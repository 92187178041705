import posthog from 'posthog-js'

export default {
  install(Vue) {
    const posthogKey = process.env.VUE_APP_POSTHOG_KEY || ''
    Vue.prototype.$posthog = posthog.init(posthogKey, {
      api_host: 'https://eu.i.posthog.com',
      capture_pageview: false,
    })
  },
}
