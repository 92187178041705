import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import { round } from 'lodash'
import { THEME_COLORS } from '@/constants/theme'

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function changeEmailToLowerCase(value) {
  if (!value) return value
  return value.toLowerCase()
}

export function formatDateInput(dateString) {
  dayjs.extend(customParseFormat)

  return new Date(dayjs(dateString, 'DD.MM.YYYY').format('MM/DD/YYYY'))
}

export const tomorrow = new Date(dayjs().add(1, 'day').format('MM/DD/YYYY'))

export function weekdaysFromSunday(dayList) {
  return dayList.map((wday) => (wday === 7 ? 1 : wday + 1))
}

export function groupAllergens(allergens) {
  const groups = {
    crustaceans: ['2'],
    celery: ['BC'],
    eggs: ['AE'],
    fish: ['AC', 'AF', '4b'],
    gluten: ['AW', 'UW', 'NR', 'GB', 'GO', 'GS', 'GK'],
    lupin: ['NP', 'NC'],
    milk: ['AM', 'ML', '7b'],
    molluscs: ['UM'],
    mustard: ['BM'],
    nuts: ['AP', 'AN', 'SA', 'SH', 'SW', 'SC', 'SP', 'SR', 'ST', 'SM'],
    pork: ['MP'],
    sesame: ['AS'],
    soy: ['AY', '6a', '6b', '6c', '6d'],
    sulphurDioxide: ['AU', 'MG'],
  }

  let accumulator = []

  for (const key in groups) {
    for (let i = 0; i < allergens.length; i++) {
      if (groups[key].includes(allergens[i].code) && !accumulator[key]) {
        accumulator[key] = allergens[i]
      }
    }
  }
  return Object.values(accumulator)
}

export function viewTypeToIconName(viewType) {
  switch (viewType) {
    case 'items_full':
    case 'template_items_full':
      return 'full-view-icon_24px'
    case 'items_card':
    case 'template_items_card':
      return 'card-view-icon_24px'
    case 'items_list':
    case 'template_items_list':
      return 'actions_list_view'
    default:
      return 'full-view-icon_24px'
  }
}

export function roundToUnitPrecision(item, num) {
  const deliveryUnit = item[item['delivery_unit'] + '_unit']
  if (!deliveryUnit) {
    return num
  }
  const afterDot = (deliveryUnit.precision + '').split('.')[1]
  if (!afterDot) {
    return round(num)
  }

  const precision = afterDot.length

  return round(num, precision)
}

export function parseLocalFloat(locale, str) {
  const normalizedStr =
    locale === 'de'
      ? str.replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '.')
      : str.replaceAll(' ', '').replaceAll(',', '')
  return parseFloat(normalizedStr)
}

export function formatLocalFloat(locale, num) {
  const str = num.toString()
  return locale === 'de' ? str.replace('.', ',') : str
}

export function mainUnit(unit) {
  let value = unit
  switch (unit) {
    case 'g':
    case 'mg':
    case 'cg':
    case 'lb':
    case 't':
      value = 'kg'
      break
    case 'cl':
    case 'ml':
      value = 'l'
      break
    default:
      value
      break
  }
  return value
}

export function orderColorByState(state) {
  if (state === 'draft') return THEME_COLORS['s1-blue-secondary-100']
  if (state === 'ordered') return THEME_COLORS['s1-purple-100']
  if (state === 'paid') return '#42a5f5'
  if (state === 'canceled') return THEME_COLORS['s1-yellow-100']
  if (state === 'delivered') return THEME_COLORS['s1-green-100']
  if (state === 'partially_delivered') return THEME_COLORS['s1-orange-100']

  return '#9e9e9e'
}

export function calculateScreenSize() {
  return {
    isXsMobileView: window.innerWidth < 425,
    isMobileView: window.innerWidth < 768,
    isSmView: window.innerWidth <= 768,
    isTabletView: window.innerWidth >= 768 && window.innerWidth < 1440,
    isDesktopView: window.innerWidth >= 1440,
    isDesktopExtraView: window.innerWidth >= 1800,
  }
}
