import pagesApi from '@/services/api/pages'

export const namespaced = true

export const state = {
  stat: {},
}

export const mutations = {
  SET_PAGE_STAT(state, data) {
    state.stat = data
  },
}

export const actions = {
  async loadPageStat({ commit }) {
    const response = await pagesApi.show()

    commit('SET_PAGE_STAT', response.data)
  },
}
