import customParseFormat from 'dayjs/plugin/customParseFormat'
import costCentersApi from '@/services/api/cost-centers'
import dayjs from 'dayjs'
import deliveriesApi from '@/services/api/deliveries'
import generatedReportApi from '@/services/api/generated_report'
import jobsApi from '@/services/api/jobs'
import ordersApi from '@/services/api/orders'
import supplierTenantsApi from '@/services/api/supplier-tenants'
import { uniqBy, sumBy, unionBy, groupBy, round, isEmpty } from 'lodash'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { roundToUnitPrecision } from '@/services/helpers'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(customParseFormat)

export const namespaced = true

export const state = {
  list: [],
  defective_items: [],
  totals: [],
  period: null,
  delivery_mode: false,
  grouped_suborders_list: {},
  count: 0,
  current_page: 1,
  selected_sub_order: null,
  current_sub_order: {
    line_items: null,
    additional_items: null,
    return_items: null,
  },
  sub_order_details: {
    selectedTabName: 'items',
    tabs: [
      {
        name: 'items',
        title: 'deliveries.items',
      },
      {
        name: 'properties',
        title: 'deliveries.properties',
      },
      {
        name: 'deliveries',
        title: 'deliveries.title',
      },
    ],
  },
  sort: {
    value: {
      name: 'shipped_at_desc',
      attr: '-deliveries.shipped_at',
    },
    options: [
      {
        name: 'shipped_at_asc',
        attr: 'deliveries.shipped_at',
      },
      {
        name: 'shipped_at_desc',
        attr: '-deliveries.shipped_at',
      },
      {
        name: 'delivery_receipt_no_asc',
        attr: 'deliveries.delivery_receipt_no',
      },
      {
        name: 'delivery_receipt_no_desc',
        attr: '-deliveries.delivery_receipt_no',
      },
    ],
  },
  filters: {
    delivery_date: {
      start_at: null,
      end_at: null,
      type: 'dateRange',
      locale_code: 'filters.items.delivery_date',
    },
    cost_centers: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      selected: false,
      withAsyncOptions: true,
    },
    delivery_type: {
      value: { name: 'all_deliveries', type: 'delivery_type' },
      options: [
        { name: 'deliveries_with_orders', type: 'delivery_type' },
        { name: 'deliveries_without_orders', type: 'delivery_type' },
        { name: 'all_deliveries', type: 'delivery_type' },
      ],
      type: 'radio',
      selected: false,
    },
    state: {
      value: [],
      options: [
        { name: '2', type: 'state' },
        { name: '3', type: 'state' },
        { name: 'late', type: 'state' },
        { name: '-', type: 'state' },
      ],
      type: 'checkbox',
      selected: false,
    },
    suppliers: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      selected: false,
      withAsyncOptions: true,
    },
    group: {
      value: { name: 'week', type: 'group' },
      options: [
        { name: 'ungrouped', type: 'group' },
        { name: 'day', type: 'group' },
        { name: 'week', type: 'group' },
        { name: 'month', type: 'group' },
      ],
      type: 'radio',
      selected: false,
    },
  },
  job_data: null,
  generated_report: null,
  fetching_order: true,
  infiniteId: +new Date(),
  pages: 0,
  per_page: 0,
  q: null,
  total_count: 0,
  isBlankState: null,
}

export const mutations = {
  ADD_ADDITIONAL_ITEM(state, item) {
    const addedItem = state.selected_sub_order.additional_items.find(
      (i) => i.id === item.id
    )

    if (addedItem) {
      addedItem.quantity++
    } else {
      const newItem = Object.assign({}, item)

      newItem.quantity = 1
      newItem.type = 'additional_items'
      newItem.isNew = true
      state.selected_sub_order.additional_items = uniqBy(
        [...state.selected_sub_order.additional_items, ...[newItem]],
        'id'
      )
    }
  },
  ADD_RETURN_ITEM(state, item) {
    const addedItem = state.selected_sub_order.return_items.find(
      (i) => i.id === item.id
    )

    if (addedItem) {
      addedItem.quantity++
    } else {
      const newItem = Object.assign({}, item)

      newItem.quantity = 1
      newItem.type = 'return_items'
      newItem.isNew = true
      state.selected_sub_order.return_items = uniqBy(
        [...state.selected_sub_order.return_items, ...[newItem]],
        'id'
      )
    }
  },
  ADD_DELIVERY(state, data) {
    state.selected_sub_order =
      data.order && data.order.id
        ? data.order
        : {
            additional_items: [],
            return_items: [],
            line_items: [],
            deliveries: [],
          }
  },
  ADD_DELIVERIES(state, { data, noFilters }) {
    state.total_count = data.total_count
    state.list = uniqBy([...state.list, ...data.deliveries], 'id')
    state.isBlankState = noFilters && data.total_count === 0
  },
  ADD_TOTALS(state, { totals }) {
    state.totals = totals
  },
  DELETE_DELIVERY(state, data) {
    state.list = state.list.filter(
      (delivery) => delivery.id !== data.delivery.id
    )
    state.total_count -= 1
  },
  APPLY_DATE_RANGE_FILTER(state, { start, end }) {
    state.filters.delivery_date.start_at = start
    state.filters.delivery_date.end_at = end
  },
  APPLY_FILTER(state, { filterItem, type }) {
    switch (type) {
      case 'checkbox':
        if (
          state.filters[filterItem.type].value.some(
            (item) => item.name === filterItem.name
          )
        ) {
          state.filters[filterItem.type].value = state.filters[
            filterItem.type
          ].value.filter((existingItem) => {
            return existingItem.name !== filterItem.name
          })
        } else {
          state.filters[filterItem.type].value.push(filterItem)
        }
        break
      case 'radio':
        state.filters[filterItem.type].value = filterItem
        break
      default:
        throw 'Undefined filter type'
    }
  },
  CHANGE_ORDER_STATE(state, { id, status }) {
    const order = state.list.find((o) => o.id === id)
    order.full_supply_state = status
  },
  CHECK_ALL_ITEMS(state, value) {
    state.defective_items = []
    state.selected_sub_order.line_items
      .filter((lineItem) => !lineItem.isTouched)
      .forEach((lineItem) => {
        lineItem.marked_as_defective_quantity = 0
        if (!value) {
          lineItem.marked_for_delivery_quantity = 0
          return
        }

        let markedForDelivery =
          lineItem.quantity - lineItem.delivery_packaging_quantity

        if (lineItem.delivery_unit === 'accounting') {
          markedForDelivery =
            lineItem.quantity * parseFloat(lineItem.unit_size) -
            lineItem.delivery_accounting_quantity
        }

        if (markedForDelivery > 0) {
          lineItem.marked_for_delivery_quantity = markedForDelivery
          lineItem.isTouched = true
        }
      })
  },
  CONFIRM_DEFECTIVE_ITEM(state, { params, item }) {
    state.defective_items = unionBy(
      [params],
      state.defective_items,
      'line_item_id'
    )

    if (item.delivery_id) {
      if (item.delivery_unit === 'accounting') {
        item.marked_as_defective_quantity =
          params.quantity - item.defective_accounting_quantity
      } else {
        item.marked_as_defective_quantity =
          params.quantity - item.defective_packaging_quantity
      }
    } else {
      item.marked_as_defective_quantity = params.quantity
    }
  },
  DECREASE_ADDITIONAL_ITEM(state, item) {
    state.selected_sub_order.additional_items =
      state.selected_sub_order.additional_items.filter((additionalItem) => {
        if (additionalItem.id === item.id) {
          additionalItem.quantity--
        }

        return additionalItem.quantity > 0
      })
  },
  DECREASE_RETURN_ITEM(state, item) {
    state.selected_sub_order.return_items =
      state.selected_sub_order.return_items.filter((returnItem) => {
        if (returnItem.id === item.id) {
          returnItem.quantity--
        }

        return returnItem.quantity > 0
      })
  },
  MARK_AS_DELIVERED(_, { item, quantity }) {
    if (item.delivery_id) {
      if (item.delivery_unit === 'accounting') {
        item.marked_for_delivery_quantity =
          quantity - (item.delivery_accounting_quantity || 0)
      } else {
        item.marked_for_delivery_quantity =
          quantity - (item.delivery_packaging_quantity || 0)
      }
    } else {
      if (quantity === 0) {
        item.marked_for_delivery_quantity = -1 // Force final price update
      }
      item.marked_for_delivery_quantity = quantity
    }
    item.isTouched = true
  },
  UNMARK_AS_DELIVERED(_, item) {
    item.marked_for_delivery_quantity = 0
  },
  RESET_LINE_ITEMS(state) {
    state.current_sub_order.line_items = []
  },
  RESET_CURRENT_SUB_ORDER(state) {
    state.current_sub_order = {
      line_items: null,
      additional_items: null,
      return_items: null,
    }
  },
  RELOAD_DELIVERIES(state) {
    state.grouped_suborders_list = {}
    state.list = []
    state.current_page = 1
    state.infiniteId += 1
    state.totals = []
  },
  REMOVE_DATE_RANGE_FILTER(state) {
    state.filters.delivery_date.start_at = null
    state.filters.delivery_date.end_at = null
  },
  REMOVE_FILTER(state, filterOption) {
    state.filters[filterOption.type].value = state.filters[
      filterOption.type
    ].value.filter((option) => option.name !== filterOption.name)
  },
  REMOVE_FILTERS(state) {
    state.filters.delivery_date.start_at = null
    state.filters.delivery_date.end_at = null
    state.filters.suppliers.value = []
    state.filters.state.value = []
    state.filters.cost_centers.value = []
  },
  REMOVE_IMAGE(state, { item, image }) {
    state.defective_items.forEach((defectiveItem) => {
      if (defectiveItem.id === item.id) {
        defectiveItem.images = defectiveItem.images.filter(
          (img) => img.name === image.name
        )
      }
    })
  },
  SELECT_SUB_ORDER(state, order) {
    state.selected_sub_order = order
    state.current_sub_order = {
      line_items: order.line_items,
      additional_items: order.additional_items,
      return_items: order.return_items,
      ...order,
    }
    state.fetching_order = false
  },
  SELECT_TAB(state, name) {
    state.sub_order_details.selectedTabName = name
  },
  SET_FILTER_LOADING(state, { type, value }) {
    state.filters[type].loading = value
  },
  SET_LINE_ITEMS(state, lineItems) {
    state.current_sub_order.line_items = lineItems
  },
  SET_PERIOD(state, value) {
    state.period = value
  },
  SET_COST_CENTER_FILTER_OPTIONS(state, options) {
    state.filters.cost_centers.options = options.map((option) => {
      return { type: 'cost_centers', ...option }
    })
  },
  SET_SUPPLIER_FILTER_OPTIONS(state, options) {
    state.filters.suppliers.options = options.map((option) => {
      return { type: 'suppliers', ...option }
    })
  },
  SET_JOB_DATA(state, jobData) {
    state.job_data = jobData
  },
  SET_GENERATED_REPORT(state, { job_data, generated_report }) {
    state.job_data = job_data
    state.generated_report = generated_report
  },
  TOGGLE_DELIVERY_MODE(state) {
    state.delivery_mode = !state.delivery_mode
    state.defective_items = []
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
  UNCHECK_ALL_ITEMS(state) {
    state.defective_items = []

    state.selected_sub_order.line_items.forEach((lineItem) => {
      lineItem.marked_as_defective_quantity = 0
      lineItem.marked_for_delivery_quantity = 0
    })
  },
  UPDATE_ADDITIONAL_ITEM_QTY(state, { item, qty }) {
    if (isNaN(qty)) return

    state.selected_sub_order.additional_items =
      state.selected_sub_order.additional_items.filter((returnItem) => {
        if (returnItem.id === item.id) {
          returnItem.quantity = qty
        }

        return returnItem.quantity > 0
      })
  },
  UPDATE_RETURN_ITEM_QTY(state, { item, qty }) {
    if (isNaN(qty)) return

    state.selected_sub_order.return_items =
      state.selected_sub_order.return_items.filter((returnItem) => {
        if (returnItem.id === item.id) {
          returnItem.quantity = qty
        }

        return returnItem.quantity > 0
      })
  },
}

export const actions = {
  addAdditionalItem({ commit }, item) {
    commit('ADD_ADDITIONAL_ITEM', item)
  },
  addReturnItem({ commit }, item) {
    commit('ADD_RETURN_ITEM', item)
  },
  applyDateRangeFilter({ commit }, { start, end }) {
    commit('APPLY_DATE_RANGE_FILTER', { start, end })
    commit('RELOAD_DELIVERIES')
  },
  applyFilter({ commit }, { filterItem, type }) {
    commit('APPLY_FILTER', { filterItem, type })
    commit('RELOAD_DELIVERIES')
  },
  checkAllItems({ commit }, value) {
    commit('CHECK_ALL_ITEMS', value)
  },
  confirmDefectiveItem({ commit }, { params, item }) {
    commit('CONFIRM_DEFECTIVE_ITEM', { params, item })
  },
  async createDelivery({ commit }, params) {
    const response = await deliveriesApi.create(params)

    commit('ADD_DELIVERY', response.data)
    commit('UNCHECK_ALL_ITEMS')
    commit('REMOVE_FILTERS')

    return response
  },
  async cancelSubOrder({ commit }, { order, send_order_cancelation_email }) {
    const response = await ordersApi.cancel.update(order.id, {
      canceled: true,
      send_order_cancelation_email: send_order_cancelation_email,
    })

    commit('CHANGE_ORDER_STATE', {
      id: response.data.id,
      status: response.data.full_supply_state,
    })
  },
  async unCancelSubOrder({ commit }, { order, send_order_cancelation_email }) {
    const response = await ordersApi.cancel.update(order.id, {
      send_order_cancelation_email: send_order_cancelation_email,
      canceled: false,
    })
    commit('CHANGE_ORDER_STATE', {
      id: response.data.id,
      status: response.data.full_supply_state,
    })
  },
  decreaseAdditionalItem({ commit }, item) {
    commit('DECREASE_ADDITIONAL_ITEM', item)
  },
  decreaseReturnItem({ commit }, item) {
    commit('DECREASE_RETURN_ITEM', item)
  },
  async deleteOrderDelivery({ commit }, { number, ...params }) {
    const response = await deliveriesApi.delete(number, params)

    commit('SELECT_SUB_ORDER', response.data.order)
    commit('order/UPDATE_CURRENT_ORDER', response.data.order, { root: true })

    return response.data.delivery
  },
  async deleteDelivery({ commit }, { number, ...params }) {
    const response = await deliveriesApi.delete(number, params)

    commit('DELETE_DELIVERY', response.data)
  },
  async getDeliveryOrder({ commit }, number) {
    const response = await deliveriesApi.orders.show(number)

    commit('SELECT_SUB_ORDER', response.data)
  },
  async getJobData({ commit }, id) {
    const response = await jobsApi.show(id)

    commit('SET_JOB_DATA', response.data)
  },
  async generateDeliveriesPdfReport({ commit }, params) {
    const response = await generatedReportApi.create(params)

    commit('SET_GENERATED_REPORT', response.data)
    return response.data
  },
  async getDeliveryReceipts(_, params) {
    const response = await deliveriesApi.delivery_receipts.index(params)
    return response.data.delivery_receipts
  },
  loadMoreLineItems({ commit }, { q, lineItems }) {
    let filteredLineItems = lineItems.filter((item) =>
      item.name.toLowerCase().includes(q.toLowerCase())
    )
    commit('SET_LINE_ITEMS', filteredLineItems)
  },
  filterLineItems({ commit }) {
    commit('RESET_LINE_ITEMS')
  },
  resetCurrentSubOrder({ commit }) {
    commit('RESET_CURRENT_SUB_ORDER')
  },
  async getDeliveries(
    { state, commit, dispatch, getters },
    infiniteLoaderContext
  ) {
    const { current_page, filters, period } = state
    const startInfiniteId = state.infiniteId

    if (state.totals.length === 0) dispatch('getTotals')

    try {
      const response = await deliveriesApi.index({
        q: state.q,
        page: current_page,
        state: filters.state.value.map((item) => item.name).join(','),
        suppliers: state.filters.suppliers.value
          .map((item) => item.id)
          .join(','),
        period,
        delivery_type:
          filters.delivery_type.value && filters.delivery_type.value.name,
        sort: state.sort.value && state.sort.value.attr,
        start_at: filters.delivery_date.start_at,
        end_at: filters.delivery_date.end_at,
        cost_centers: state.filters.cost_centers.value.map((item) => item.id),
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        state.current_page += 1
        infiniteLoaderContext.loaded()
      }

      commit('ADD_DELIVERIES', {
        data: response.data,
        noFilters: isEmpty(state.q) && isEmpty(getters.appliedFilters),
      })
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  async getTotals({ state, commit }) {
    const { filters, period } = state

    const response = await deliveriesApi.totals.index({
      q: state.q,
      state: filters.state.value.map((item) => item.name).join(','),
      suppliers: state.filters.suppliers.value.map((item) => item.id).join(','),
      period,
      delivery_type:
        filters.delivery_type.value && filters.delivery_type.value.name,
      group: filters.group.value.name,
      start_at: filters.delivery_date.start_at,
      end_at: filters.delivery_date.end_at,
      cost_centers: state.filters.cost_centers.value.map((item) => item.id),
    })

    commit('ADD_TOTALS', response.data)
  },
  async loadCostCentersFilterOptions({ commit }, { company_id }) {
    commit('SET_FILTER_LOADING', { value: true, type: 'cost_centers' })

    const response = await costCentersApi.index({ all: true, company_id })

    commit('SET_COST_CENTER_FILTER_OPTIONS', response.data.cost_centers)

    commit('SET_FILTER_LOADING', { value: false, type: 'cost_centers' })
  },
  async loadSuppliersFilterOptions({ commit }) {
    commit('SET_FILTER_LOADING', { value: true, type: 'suppliers' })

    const response = await supplierTenantsApi.index({ all: true })

    commit('SET_SUPPLIER_FILTER_OPTIONS', response.data.suppliers)

    commit('SET_FILTER_LOADING', { value: false, type: 'suppliers' })
  },
  markAsDelivered({ commit }, { item, quantity }) {
    commit('MARK_AS_DELIVERED', { item, quantity })
  },
  unMarkAsDelivered({ commit }, item) {
    commit('UNMARK_AS_DELIVERED', item)
  },
  async reloadDeliveries({ commit }) {
    await commit('RELOAD_DELIVERIES')
  },
  removeDateRangeFilter({ commit }) {
    commit('REMOVE_DATE_RANGE_FILTER')
    commit('RELOAD_DELIVERIES')
  },
  removeFilter({ commit }, filterOption) {
    commit('REMOVE_FILTER', filterOption)
    commit('RELOAD_DELIVERIES')
  },
  removeFilters({ commit }) {
    commit('REMOVE_FILTERS')
    commit('RELOAD_DELIVERIES')
  },
  removeImage({ commit }, { item, image }) {
    commit('REMOVE_IMAGE', { item, image })
  },
  selectSubOrder({ commit }, order) {
    commit('SELECT_SUB_ORDER', order)
  },
  selectTab({ commit }, name) {
    commit('SELECT_TAB', name)
  },
  setPeriod({ commit }, value) {
    commit('SET_PERIOD', value)
  },
  toggleDeliveryMode({ commit }) {
    commit('TOGGLE_DELIVERY_MODE')
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  updateAdditionalItemQty({ commit }, { item, qty }) {
    commit('UPDATE_ADDITIONAL_ITEM_QTY', { item, qty })
  },
  updateReturnItemQty({ commit }, { item, qty }) {
    commit('UPDATE_RETURN_ITEM_QTY', { item, qty })
  },
}

export const getters = {
  anyItemMarkedForDelivery: (state) => {
    const {
      selected_sub_order: { line_items, additional_items, return_items },
    } = state

    return (
      line_items.some(
        (item) =>
          item.marked_for_delivery_quantity > 0 ||
          item.marked_as_defective_quantity > 0
      ) ||
      ([...additional_items, ...return_items].some(
        (item) => item.quantity > 0
      ) &&
        state.selected_sub_order.full_supply_state === 'delivered')
    )
  },
  areDeliveriesGrouped(state) {
    return state.filters.group.value.name !== 'ungrouped'
  },
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
  appliedFilters: (state) => {
    const { filters } = state

    return [
      ...filters.state.value,
      ...filters.suppliers.value,
      ...filters.cost_centers.value,
      {
        name: filters.delivery_date.start_at
          ? `${filters.delivery_date.start_at} - ${filters.delivery_date.end_at}`
          : null,
        type: 'dateRange',
      },
    ].filter((filterItem) => filterItem && filterItem.name)
  },
  finalItemDeliveredQuantity: () => (item) => {
    let deliveryQuantity = parseInt(item.delivery_packaging_quantity)

    if (item.delivery_unit === 'accounting') {
      deliveryQuantity = parseFloat(item.delivery_accounting_quantity)
    }

    if (!deliveryQuantity && item.isTouched) {
      deliveryQuantity = 0
    }

    const finalDeliveryQuantity =
      deliveryQuantity + item.marked_for_delivery_quantity

    return roundToUnitPrecision(item, finalDeliveryQuantity)
  },
  finalItemDefectiveQuantity: () => (item) => {
    return item.defective_packaging_quantity + item.marked_as_defective_quantity
  },
  finalOrderDeliveredItemsQuantity: () => (order) => {
    const deliveredQuantity = sumBy(order.line_items, (lineItem) => {
      const deliveryUnitPrice =
        lineItem.delivery_unit === 'accounting'
          ? parseFloat(lineItem.amount_per_unit)
          : parseFloat(lineItem.price)

      const deliveredUnitQuantity =
        lineItem.delivery_unit === 'accounting'
          ? parseFloat(lineItem.delivery_accounting_quantity) || 0
          : parseInt(lineItem.delivery_packaging_quantity) || 0

      const deliveryPrice =
        (lineItem.marked_for_delivery_quantity + deliveredUnitQuantity) *
        deliveryUnitPrice

      const deliveryQuantity =
        lineItem.quantity * (deliveryPrice / lineItem.amount)

      return deliveryQuantity > lineItem.quantity
        ? lineItem.quantity
        : deliveryQuantity
    })

    return deliveredQuantity % 1 === 0
      ? round(deliveredQuantity)
      : round(deliveredQuantity, 2)
  },
  finalOrderDefectiveItemsQuantity: () => (order) => {
    return (
      sumBy(order.deliveries, 'defective_packaging_quantity') +
      sumBy(order.line_items, 'marked_as_defective_quantity')
    )
  },
  hasDeliverableItems: () => (order) => {
    const hasRegularDeliverableItems =
      sumBy(order.line_items, 'marked_for_delivery_quantity') +
        sumBy(order.line_items, 'marked_as_defective_quantity') >=
        0 && order.line_items.some((item) => item.isTouched === true)

    const hasAdditionalReturnItems =
      order.additional_items.some((item) => item.isNew === true) ||
      order.return_items.some((item) => item.isNew === true)

    return hasRegularDeliverableItems || hasAdditionalReturnItems
  },
  hasAdditionalItems(state) {
    return (
      state.selected_sub_order.additional_items &&
      state.selected_sub_order.additional_items.length > 0
    )
  },
  additionalItemsQty(state) {
    return sumBy(state.selected_sub_order.additional_items, 'quantity')
  },
  hasReturnItems(state) {
    return (
      state.selected_sub_order.return_items &&
      state.selected_sub_order.return_items.length > 0
    )
  },
  returnItemsQty(state) {
    return sumBy(state.selected_sub_order.return_items, 'quantity')
  },
  hasDefectiveItems: (_, getters) => (order) => {
    return getters.finalOrderDefectiveItemsQuantity(order) > 0
  },
  isDefective: () => (item) => {
    return item.marked_as_defective_quantity > 0
  },
  isMarkedForDelivery: () => (item) => {
    return item.marked_for_delivery_quantity > 0
  },
  maxDefectiveQuantity: () => (item) => {
    const quantity = item.delivery_id
      ? item.available_packaging_quantity + item.defective_packaging_quantity
      : item.quantity -
        item.defective_packaging_quantity -
        item.delivery_packaging_quantity
    return quantity - item.marked_for_delivery_quantity
  },
  maxDeliverQuantity: () => (item) => {
    const deliveryQuantity =
      item.delivery_unit === 'accounting'
        ? parseFloat(item.delivery_accounting_quantity) || 0
        : parseInt(item.delivery_packaging_quantity) || 0

    const availableQuantity =
      item.delivery_unit === 'accounting'
        ? parseFloat(item.available_accounting_quantity)
        : parseInt(item.available_packaging_quantity)

    const defectiveQuantity =
      item.delivery_unit === 'accounting'
        ? parseFloat(item.defective_accounting_quantity)
        : parseInt(item.defective_packaging_quantity)

    const itemQuantity =
      item.delivery_unit === 'accounting'
        ? parseFloat(item.accounting_quantity)
        : parseInt(item.quantity)

    const quantity = item.delivery_id
      ? availableQuantity + deliveryQuantity
      : itemQuantity - defectiveQuantity - deliveryQuantity

    const markedAsDefectiveQuantity =
      item.delivery_unit === 'accounting'
        ? item.marked_as_defective_quantity * parseFloat(item.unit_size)
        : item.marked_as_defective_quantity

    const maxQuantity = quantity - markedAsDefectiveQuantity

    return maxQuantity > 0 ? roundToUnitPrecision(item, maxQuantity) : 0
  },
  totalOrderAdditionalReturnedItemsCount: () => (order) => {
    return (
      sumBy(order.additional_items, 'quantity') +
      sumBy(order.return_items, 'quantity')
    )
  },
  totalOrderOrderedItemsCount: () => (order) => {
    return sumBy(order.line_items, 'quantity')
  },
  totalOrderDeliveredItemsCount: () => (order) => {
    const deliveredQuantity = sumBy(order.line_items, (lineItem) => {
      const deliveryUnitPrice =
        lineItem.delivery_unit === 'accounting'
          ? parseFloat(lineItem.amount_per_unit)
          : parseFloat(lineItem.price)

      const deliveredUnitQuantity =
        lineItem.delivery_unit === 'accounting'
          ? parseFloat(lineItem.delivery_accounting_quantity)
          : parseInt(lineItem.delivery_packaging_quantity)

      const deliveryPrice = deliveredUnitQuantity * deliveryUnitPrice

      const deliveryQuantity =
        lineItem.quantity * (deliveryPrice / lineItem.amount)

      return deliveryQuantity > lineItem.quantity
        ? lineItem.quantity
        : deliveryQuantity
    })

    return deliveredQuantity % 1 === 0
      ? round(deliveredQuantity)
      : round(deliveredQuantity, 2)
  },
  totalOrderDefectiveItemsCount: () => (order) => {
    return sumBy(order.line_items, 'defective_packaging_quantity')
  },
  totalOrderItemsCount: (_state, getters) => (order) => {
    return (
      getters.totalOrderOrderedItemsCount(order) +
      getters.totalOrderDeliveredItemsCount(order) +
      getters.totalOrderDefectiveItemsCount(order) +
      getters.totalOrderAdditionalReturnedItemsCount(order)
    )
  },
  groupedDeliveries(state) {
    const groupFunc = (state, delivery) => {
      const date = dayjs(delivery.shipped_at, 'DD.MM.YYYY')

      if (state.filters.group.value.name === 'week') {
        return `${date.week()}: ${date.weekday(1).format('DD.MM')} - ${date
          .weekday(7)
          .format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'day') {
        return `${date.format('dddd')}:${date.format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'month') {
        return date.format('MM:YYYY')
      }

      return 'all'
    }

    const totals = groupBy(state.totals, (total) => groupFunc(state, total))
    const deliveries = groupBy(state.list, (order) => groupFunc(state, order))

    return { deliveries, totals }
  },

  groupedDeliveriesByCostCenter: (state) => (cost_center) => {
    const groupFunc = (state, delivery) => {
      const date = dayjs(delivery.shipped_at, 'DD.MM.YYYY')

      if (state.filters.group.value.name === 'week') {
        return `${date.week()}: ${date.weekday(1).format('DD.MM')} - ${date
          .weekday(7)
          .format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'day') {
        return `${date.format('dddd')}:${date.format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'month') {
        return date.format('MM:YYYY')
      }

      return 'all'
    }

    const totals = groupBy(
      state.totals.filter((total) => total.cost_center_name === cost_center),
      (total) => groupFunc(state, total)
    )
    const deliveries = groupBy(
      state.list.filter(
        (delivery) => delivery.cost_center.name === cost_center
      ),
      (delivery) => groupFunc(state, delivery)
    )

    return { deliveries, totals }
  },

  deliveriesCostCenters(state) {
    return [...new Set(state.list.map((delivery) => delivery.cost_center.name))]
  },
}
