<template>
  <div
    class="flex items-center justify-center font-medium text-alabaster uppercase"
    :class="{ 'rounded-full': isCircle, rounded: !isCircle }"
    :style="`background: ${colorCode};`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    colorCode: {
      type: String,
    },
    isCircle: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
