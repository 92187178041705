import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/master_orders', { params })
  },
  update(id, params) {
    return apiClient.put(`api/orders/${id}`, params)
  },
  show(number) {
    return apiClient.get(`api/master_orders/${number}`)
  },
  current: {
    update(params) {
      return apiClient.put('api/orders/current', params)
    },
  },
  groups: {
    index(params) {
      return apiClient.get('api/orders/groups', { params })
    },
  },
  pdf: {
    index(params) {
      return apiClient.get('api/master_orders.pdf', {
        params,
        responseType: 'blob',
      })
    },
  },
}
