import countriesApi from '@/services/api/countries'
import { groupBy } from 'lodash'

export const namespaced = true

export const state = {
  companyTypes: [{ name: 'national' }, { name: 'regional' }],
  countries: [],
  states: [],
  defaultCountry: null,
  loading: false,
}

export const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries
    state.defaultCountry = countries.find((country) => country.iso === 'DE')
    state.loading = false
  },
  SET_STATES(state, states) {
    state.states = states
    state.loading = false
  },
}

export const actions = {
  getCountries({ state, commit }) {
    state.loading = true

    return countriesApi.index().then((response) => {
      commit('SET_COUNTRIES', response.data)
    })
  },
  async getStates({ state, commit }, iso) {
    state.loading = true

    const response = await countriesApi.states.index(iso)

    commit('SET_STATES', response.data)
  },
}

export const getters = {
  getCompanyTypeById: (state) => (typeId) => {
    return state.companyTypes.filter((type) => type.id === typeId)[0]
  },
  groupedStates(state) {
    return groupBy(state.states, (item) => item.abbr.substring(0, 5))
  },
}
