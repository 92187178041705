<template>
  <span
    :class="`icon-side-nav_${name} text-${color} text-${size} font-regular`"
  ></span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      require: true,
    },
    color: {
      type: String,
      default: 'medium-gray',
    },
    size: {
      type: String,
      default: 'base',
    },
  },
}
</script>
