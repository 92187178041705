import invitations from '@/services/api/invitations'

export const namespaced = true

export const state = {
  inviteeData: {},
}

export const mutations = {
  SET_INVITEE_DATA(state, data) {
    state.inviteeData = data
  },
}

export const actions = {
  async getUserByInvitationToken({ commit }, token) {
    const response = await invitations.show(token)

    commit('SET_INVITEE_DATA', response.data)
  },
  async acceptInvitation({ commit }, { token, params }) {
    const response = await invitations.update(token, params)

    commit('currentUser/SET_CURRENT_USER_TOKENS', response.data, {
      root: true,
    })
    commit('currentUser/SET_CURRENT_USER_DATA', response.data, {
      root: true,
    })

    return response.data.user
  },
}
