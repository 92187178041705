var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative rounded-10 h-14 bg-s1-main-100",class:{
    'ring-2 ring-s1-blue-100 focus:outline-none with-border': _vm.isOpened,
    'border-0 ring-s1-gray-50 ring-1': !_vm.isOpened,
    'hover:ring-s1-blue-100': !_vm.disabled,
    'opacity-50': _vm.disabled,
    'invalid-select-input': _vm.invalid,
  }},[(_vm.showLabel)?_c('label',{staticClass:"absolute top-0 z-30 block pl-1 pt-0 mt-1 ml-2 text-xxs pointer-events-none bg-s1-main-100 text-input-label",class:{ 'z-30': !_vm.isOpened, 'z-60': _vm.isOpened }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('Multiselect',_vm._b({class:{
      'multiselect__tags--invalid': _vm.invalid,
    },attrs:{"deselectLabel":"","open-direction":"openDirection","selectLabel":"","selectedLabel":"","disabled":_vm.disabled,"loading":_vm.loading,"options":_vm.options,"placeholder":_vm.placeholder,"track-by":_vm.trackBy,"value":_vm.value,"custom-label":_vm.customLabel,"preselect-first":_vm.preselect,"clear-on-select":_vm.clearOnSelect,"preserve-search":_vm.preserveSearch},on:{"input":_vm.changeValue,"open":_vm.openOptions,"search-change":_vm.searchChange,"select":_vm.selectValue,"remove":_vm.removeValue,"close":_vm.closeOptions},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"caret",fn:function(){return [_c('div',{staticClass:"absolute right-0 flex items-center h-full"},[_c('ActionIcon',{staticClass:"text-input-label text-2xl pr-3",attrs:{"name":"actions_cart_dropdown"}})],1)]},proxy:true},{key:"noOptions",fn:function(){return [_vm._v(_vm._s(_vm.$t('shared.list_is_empty')))]},proxy:true}],null,true)},'Multiselect',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }