import apiClient from '@/services/api'

export default {
  revert: {
    create(itemsCsvAttachmentId) {
      return apiClient.post(
        `api/items_csv_attachments/${itemsCsvAttachmentId}/revert`
      )
    },
  },
}
