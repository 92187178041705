import apiClient from '@/services/api'

export default {
  index() {
    return apiClient.get('api/countries')
  },
  states: {
    index(countryIso) {
      return apiClient.get(`api/countries/${countryIso}/states`)
    },
  },
}
