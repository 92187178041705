import lookupsApi from '@/services/api/lookups'
import { uniqBy } from 'lodash'

export const namespaced = true

export const state = {
  additives: {
    loading: false,
    options: [],
  },
  allergens: {
    loading: false,
    options: [],
  },
  base_unit_types: {
    loading: false,
    options: [],
  },
  item_types: {
    loading: false,
    options: [],
  },
  packaging_types: {
    loading: false,
    options: [],
  },
  product_groups: {
    total_count: 0,
    current_page: 0,
    q: null,
    loading: false,
    options: [],
    infiniteId: +new Date(),
  },
  replacement_groups: {
    total_count: 0,
    current_page: 0,
    q: null,
    loading: false,
    options: [],
    infiniteId: +new Date(),
  },
  bls_replacement_groups: {
    selector: 'replacement_groups',
    total_count: 0,
    current_page: 0,
    q: null,
    loading: false,
    options: [],
    infiniteId: +new Date(),
  },
  supplier_tenants: {
    loading: false,
    options: [],
  },
  tax_categories: {
    loading: false,
    options: [],
  },
}

export const mutations = {
  ADD_PAGINATED_OPTIONS(state, { type, data }) {
    const options = data[state[type].selector || type]
    state[type].options = uniqBy([...state[type].options, ...options], 'id')
    state[type].current_page += 1
    state[type].total_count = data.total_count
  },
  SET_LOADING(state, { type, value }) {
    state[type].loading = value
  },
  SET_OPTIONS(state, { type, value }) {
    state[type].options = value
  },
  RESET_OPTIONS(state, { type, q }) {
    state[type].q = q
    state[type].options = []
    state[type].current_page = 0
    state[type].infiniteId += 1
  },
}

export const actions = {
  filterLookupOptions({ commit }, { type, q = null }) {
    commit('RESET_OPTIONS', { type, q })
  },
  async getLookupOptions({ commit }, { type, params }) {
    commit('SET_LOADING', { type, value: true })

    const response = await lookupsApi[type].index(params)

    commit('SET_OPTIONS', { type, value: response.data[type] })
    commit('SET_LOADING', { type, value: false })

    return response
  },
  async getPaginatedLookupOptions(
    { commit, state },
    { infiniteLoaderContext, type, params }
  ) {
    const { current_page, q } = state[type]

    try {
      const response = await lookupsApi[type].index({
        ...params,
        page: current_page + 1,
        q,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_PAGINATED_OPTIONS', { type, data: response.data })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
}
