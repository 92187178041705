import masterOrdersApi from '@/services/api/master-orders'

export const namespaced = true

export const state = {
  resource: null,
  q: null,
  total_count: 0,
  sort: {
    value: {
      name: 'created_at_desc',
      attr: '-master_orders.created_at',
      group_attr: '-master_orders.created_at',
    },
    options: [
      {
        name: 'created_at_asc',
        attr: 'master_orders.created_at',
        group_attr: 'master_orders.created_at',
      },
      {
        name: 'created_at_desc',
        attr: '-master_orders.created_at',
        group_attr: '-master_orders.created_at',
      },
      {
        name: 'id_asc',
        attr: 'master_orders.id',
        group_attr: 'master_orders.id',
      },
      {
        name: 'id_desc',
        attr: '-master_orders.id',
        group_attr: '-master_orders.id',
      },
      {
        name: 'total_asc',
        attr: 'sum(spree_orders.total)',
        group_attr: 'total',
      },
      {
        name: 'total_desc',
        attr: '-sum(spree_orders.total)',
        group_attr: '-total',
      },
    ],
  },
}

export const mutations = {
  UPDATE_SUB_ORDER(state, order) {
    state.resource.sub_orders = state.resource.sub_orders.map((subOrder) => {
      return subOrder.id === order.id ? order : subOrder
    })
  },
  SET_MASTER_ORDER(state, masterOrder) {
    state.resource = masterOrder
    state.total_count = masterOrder.sub_orders.length
  },
}

export const actions = {
  async getMasterOrder({ commit }, number) {
    const response = await masterOrdersApi.show(number)

    commit('SET_MASTER_ORDER', response.data)
  },
}
