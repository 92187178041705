import '@/assets/css/tailwind.css'
import 'nprogress/nprogress.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'
import '@/assets/css/vue-multiselect-overrides.css'
import '@/assets/css/vue-swipe-actions-override.css'
import '@/assets/css/tooltip.css'
import '@/assets/css/notification.css'
import '@/assets/css/animations.css'
import '@/assets/css/vue-calendar.css'
import '@/assets/css/icon-font.css'

import ActionCableVue from 'actioncable-vue'
import Appsignal from '@appsignal/javascript'
import { errorHandler } from '@appsignal/vue'
import Notifications from 'vue-notification'
import resize from 'vue-element-resize-detector'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable'
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar'
import VueApexCharts from 'vue-apexcharts'
import { VTooltip } from 'v-tooltip'
import { abilitiesPlugin } from '@casl/vue'

import App from '@/app.vue'
import { connectionUrl } from '@/services/socket'
import router from '@/router'
import store from '@/store'
import titleMixin from '@/mixins/title'
import ability from '@/services/ability'
import { languages, defaultLocale } from '@/i18n'
import EmptyTemplate from '@/components/shared/templates/empty.vue'
import MainTemplate from '@/components/shared/templates/main.vue'
import posthogPlugin from '@/plugins/posthog'

import { mapGetters, mapState } from 'vuex'

import '@/registerServiceWorker'

if (process.env.VUE_APP_APP_SIGNAL_KEY) {
  const appsignal = new Appsignal({
    key: process.env.VUE_APP_APP_SIGNAL_KEY,
  })

  Vue.config.errorHandler = errorHandler(appsignal, Vue)
}

if (process.env.NODE_ENV === 'test') {
  const div = {
    functional: true,
    render: (h, { data, children }) => h('div', data, children),
  }

  Vue.component('transition', div)
  Vue.component('transition-group', div)
}

// https://github.com/kazupon/vue-i18n/issues/474
const messages = Object.assign(languages)

Vue.config.productionTip = false

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'all', // info, all
  connectImmediately: false,
  connectionUrl: connectionUrl,
})

Vue.use(resize)
Vue.use(abilitiesPlugin, ability())
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueI18n)
Vue.use(VCalendar)
Vue.use(VueApexCharts)
Vue.use(posthogPlugin)

Vue.directive('tooltip', VTooltip)
Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  },
})

Vue.mixin(titleMixin)

Vue.component('apexchart', VueApexCharts)
Vue.component('empty-template', EmptyTemplate)
Vue.component('main-template', MainTemplate)

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
    },
  },
}

const dateTimeFormats = {
  en: {
    digit: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  de: {
    digit: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
}

const i18n = createI18n({
  locale: defaultLocale(),
  fallbackLocale: 'en',
  messages,
  numberFormats,
  dateTimeFormats,
})

new Vue({
  router,
  store,
  i18n,
  channels: {
    AppearanceChannel: {
      connected() {},
    },
    CurrentUserChannel: {
      connected() {},
      received(data) {
        if (data.event === 'logout') {
          this.$store.commit('currentUser/REMOVE_CURRENT_USER_TOKENS')
        } else if (data.event === 'items_updated') {
          this.$store.commit('currentUser/ADD_NOTIFICATION', {
            name: 'items_updated_statistic',
            data: data,
          })
        } else if (data.event === 'simple_notification') {
          this.$notify({
            group: 'common',
            title: this.$t(`shared.notifications.${data.type}.title`),
            type: data.type,
            duration: 15000,
            text: data.text,
          })
        }
      },
    },
  },
  data() {
    return {
      isSubscribeCalled: false,
    }
  },
  mounted() {
    this.subscribeToChannels()
  },
  created() {
    const userString = localStorage.getItem('currentUser')

    if (userString) {
      const currentUser = JSON.parse(userString)
      this.$store.commit('currentUser/SET_CURRENT_USER_DATA', {
        user: currentUser,
      })
    }
  },
  watch: {
    'currentUser.currentUser': {
      handler(user, prevUser) {
        if (!user.id) return this.$posthog.reset()

        this.subscribeToChannels()
        if (user.id !== prevUser.id) this.setupPosthog(user)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('currentUser', ['theme']),
    ...mapState(['currentUser']),
  },
  methods: {
    subscribeToChannels() {
      if (this.isSubscribeCalled) return

      if (localStorage.getItem('REFRESH_TOKEN')) {
        this.$cable.subscribe({ channel: 'AppearanceChannel' })
        this.$cable.subscribe({ channel: 'CurrentUserChannel' })
        this.isSubscribeCalled = true
      }
    },

    setupPosthog(user) {
      const account = user.account?.value || {}
      const company = user.preference?.company || {}
      const cost_center = user.preference?.cost_center || {}
      const roles = user.roles ? Array.from(new Set(user.roles.sort())) : []

      this.$posthog.identify(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        roles: roles.join(' '),
        account: account.name,
        company: company.name,
        cost_center: cost_center.name,
      })

      this.$posthog.group('account', account.id, { name: account.name })
      this.$posthog.group('cost_center', cost_center.id, {
        name: cost_center.name,
      })
      this.$posthog.group('company', company.id, { name: company.name })
      this.$posthog.group('roles', roles.join('-'), { name: roles.join(' ') })
    },
  },
  provide: {
    $posthog: Vue.prototype.$posthog,
  },
  render: (h) => h(App),
}).$mount('#app')
