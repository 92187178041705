import apiClient from '@/services/api'

export default {
  create(params) {
    return apiClient.post('api/deliveries/generated_reports', params)
  },
  pdf: {
    show(id) {
      return apiClient.get(`api/generated_reports/${id}.pdf`, {
        responseType: 'blob',
      })
    },
  },
}
