<template>
  <span
    :class="`icon-nav_${name} text-${color} text-${size} font-regular`"
  ></span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      require: true,
      validator(value) {
        return [
          'expand_more',
          'expand_less',
          'unfold_more',
          'unfold_less',
          'navigate_before',
          'navigate_next',
          'three-dots',
          'menu',
          'camera_alt',
          'delivery',
        ].includes(value)
      },
    },
    color: {
      type: [String, Object],
      default: 'medium-gray',
    },
    size: {
      type: String,
      default: 'xl',
    },
  },
}
</script>
