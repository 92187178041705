import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/deliveries', { params })
  },
  create(params) {
    return apiClient.post('api/deliveries', params)
  },
  show(number) {
    return apiClient.get(`api/deliveries/${number}`)
  },
  update(formData) {
    return apiClient.put(`api/deliveries/${formData.get('number')}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  delete(number, params) {
    return apiClient.delete(`api/deliveries/${number}`, { data: params })
  },
  attachment: {
    create(formData) {
      return apiClient.post(
        `api/deliveries/${formData.get(
          'delivery_number'
        )}/delivery_attachments`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    },
    delete(id) {
      return apiClient.delete(`api/delivery_attachments/${id}`)
    },
  },
  inventory_units: {
    show(id) {
      return apiClient.get(`api/inventory_units/${id}`)
    },
    return_authorization_images: {
      create(formData) {
        return apiClient.post(
          `api/inventory_units/${formData.get(
            'inventory_unit_id'
          )}/return_authorization_images`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
      },
      delete(id) {
        return apiClient.delete(`api/return_authorization_images/${id}`)
      },
    },
    return_authorizations: {
      create(id, params) {
        return apiClient.post(
          `api/inventory_units/${id}/return_authorizations`,
          params
        )
      },
      update(id, params) {
        return apiClient.put(`api/return_authorizations/${id}`, params)
      },
    },
  },
  pdf: {
    index(params) {
      return apiClient.get(`api/deliveries.pdf`, {
        params,
        responseType: 'blob',
      })
    },
  },
  versions: {
    index(number, params) {
      return apiClient.get(`api/deliveries/${number}/versions`, { params })
    },
  },
  orders: {
    show(number) {
      return apiClient.get(`api/deliveries/orders/${number}`)
    },
  },
  totals: {
    index(params) {
      return apiClient.get(`api/deliveries/totals`, { params })
    },
  },
  delivery_receipts: {
    index(params) {
      return apiClient.get('api/deliveries/delivery_receipts', { params })
    },
  },
}
