import settingsApi from '@/services/api/settings'

export const namespaced = true

export const state = {
  product_groups: {
    quantity: 0,
  },
  allergens: {
    quantity: 0,
  },
  additives: {
    quantity: 0,
  },
  tax_rates: {
    quantity: 0,
  },
  imports: {
    quantity: 0,
  },
  units: {
    quantity: 0,
  },
}

export const mutations = {
  SET_SETTINGS_DATA(state, data) {
    state.product_groups = data.product_groups
    state.allergens = data.allergens
    state.additives = data.additives
    state.tax_rates = data.tax_rates
    state.units = data.units
  },
}

export const actions = {
  async getSettings({ commit }) {
    const response = await settingsApi.show()

    commit('SET_SETTINGS_DATA', response.data)
  },
}
