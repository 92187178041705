import { calculateScreenSize } from '@/services/helpers'

export default {
  data() {
    return {
      isXsMobileView: false,
      isMobileView: false,
      isSmView: false,
      isTabletView: false,
      isDesktopView: false,
      isDesktopExtraView: false,
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)

    this.handleWindowResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  methods: {
    handleWindowResize() {
      const result = calculateScreenSize()

      this.isXsMobileView = result.isXsMobileView
      this.isMobileView = result.isMobileView
      this.isSmView = result.isSmView
      this.isTabletView = result.isTabletView
      this.isDesktopView = result.isDesktopView
      this.isDesktopExtraView = result.isDesktopExtraView
    },
  },
}
