import settingsApi from '@/services/api/settings'

export const namespaced = true

export const state = {
  list: [],
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  infiniteId: +new Date(),
}

export const mutations = {
  SET_ADDITIVES_DATA(state, { additives, total_count }) {
    state.list = [...state.list, ...additives]
    state.current_page += 1
    state.total_count = total_count
  },
  CLEAR_ADDITIVES(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
}

export const actions = {
  async getAdditives({ commit, state }, infiniteLoaderContext) {
    try {
      const response = await settingsApi.additives.index({
        page: state.current_page + 1,
        q: state.q,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('SET_ADDITIVES_DATA', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async clearAdditives({ commit }) {
    commit('CLEAR_ADDITIVES')
  },
}
