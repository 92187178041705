<template>
  <div
    id="app"
    data-cy="app"
    class="flex flex-col justify-between min-h-full text-sm font-regular text-s1-gray-100"
    :class="`theme-${theme}`"
  >
    <notifications
      group="common"
      position="bottom center"
      :width="isMobileView ? '350' : '512'"
    >
      <template #body="props">
        <div
          class="flex flex-row p-4 mb-4 rounded-xl text-permanent-white notification"
          :class="props.item.type"
          data-cy="notification"
        >
          <Avatar
            v-if="props.item.data && props.item.data.user"
            :user="props.item.data && props.item.data.user"
            size="md"
            initialsTextColor="permanent-white"
          />
          <div class="flex-none w-6 h-6 my-auto mr-4 notification-icon" />
          <div class="flex-grow overflow-hidden">
            <div class="text-base font-medium break-words truncate">
              {{ props.item.title }}
            </div>
            <div v-html="props.item.text" />
            <div v-if="props.item.data && props.item.data.action">
              <div
                @click.prevent="props.item.data.action.callback"
                v-html="props.item.data.action.text"
              />
            </div>
          </div>
          <a
            class="flex flex-none w-6 h-6 my-auto ml-4 cursor-pointer notification-close"
            @click="props.close"
          ></a>
        </div>
      </template>
    </notifications>

    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import Avatar from '@/components/shared/atoms/avatar.vue'
import detectScreenSize from '@/mixins/detect-screen-size'
import { mapGetters } from 'vuex'

export default {
  components: {
    Avatar,
  },
  mixins: [detectScreenSize],
  computed: {
    ...mapGetters('currentUser', ['theme']),
  },
}
</script>
