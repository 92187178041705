<template>
  <div
    class="relative"
    :class="{
      'w-26 h-26': !overlayed && size === 'lg',
      'w-10 h-10': !overlayed && size === 'md',
      'w-8 h-8': !overlayed && size === 'sm',
      'w-7 h-7': !overlayed && size === 'xxs',
    }"
  >
    <img
      v-if="data && data.logo.url"
      class="object-cover"
      :class="{
        'w-7 h-7': size === 'xxs',
        'w-8 h-8': size === 'sm',
        'w-10 h-10': size === 'md',
        'w-full h-full': size === 'full',
        'md:w-8 md:h-8 md:text-sm': overlayed,
        'rounded-full': isCircle,
        rounded: !isCircle,
      }"
      alt=""
      :src="attachmentFullUrl(data.logo.url)"
      v-tooltip="{
        content: `${data.name}`,
        placement: tooltipPlacement,
      }"
    />

    <InitialsIcon
      v-else
      class="inline-block object-cover text-sm bg-mine-shaft"
      :is-circle="isCircle"
      :class="{
        'w-7 h-7 text-sm': size === 'xxs',
        'w-8 h-8 text-sm': size === 'sm',
        'w-10 h-10': size === 'md',
        'w-full h-full text-xl': size === 'full',
        'md:w-8 md:h-8 md:text-sm': overlayed,
      }"
      v-tooltip="{
        content: `${data ? data.name : ''}`,
        placement: tooltipPlacement,
      }"
    >
      <span class="text-gallery">{{ data ? data.name[0] : '' }}</span>
    </InitialsIcon>
  </div>
</template>

<script>
import InitialsIcon from '@/components/shared/atoms/initials-circle'

export default {
  props: {
    data: {
      type: Object,
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['xxs', 'sm', 'md', 'full'].includes(value)
      },
    },
    overlayed: {
      type: Boolean,
      default: false,
    },
    isCircle: {
      type: Boolean,
      default: true,
    },
    tooltipPlacement: {
      type: String,
      default: 'auto',
    },
  },
  components: {
    InitialsIcon,
  },
  computed: {
    attachmentFullUrl: () => (url) => {
      return process.env.VUE_APP_API_URL + url
    },
  },
}
</script>
