<template>
  <div @click="$emit('click')">
    <img
      v-if="isDarkMode || white"
      src="@/assets/images/svg/supply-one-dark.svg"
      alt="Logo"
    />
    <img v-else src="@/assets/images/svg/supply-one-light.svg" alt="Logo" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('currentUser', ['isDarkMode']),
  },
}
</script>
