import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/procurements', { params })
  },

  pdf: {
    index(params) {
      return apiClient.get('api/procurements.pdf', {
        params,
        responseType: 'blob',
      })
    },
  },
  items: {
    index(params) {
      return apiClient.get('api/procurements/items', { params })
    },
    pdf: {
      index(params) {
        return apiClient.get('api/procurements/items.pdf', {
          params,
          responseType: 'blob',
        })
      },
    },
  },
}
