import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/expiring_prices', { params })
  },
  item_list: {
    update(params) {
      return apiClient.put(`api/expiring_prices/item_list`, params)
    },
  },
}
