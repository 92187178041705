import apiClient from '@/services/api'

export default {
  create(params) {
    return apiClient.post('api/session', params)
  },
  update() {
    return apiClient.put('api/session')
  },
  delete() {
    return apiClient.delete('api/session')
  },
}
