<template>
  <div
    class="flex flex-grow items-center content-center w-full h-full background"
  >
    <div class="p-10 mx-auto text-center">
      <p class="text-4xl sm:text-5xl xl:text-6xl text-medium-gray">
        {{ $t('not_found.title') }}
      </p>

      <img class="mx-auto my-8" src="@/assets/images/svg/404.svg" alt="" />

      <p class="text-xl uppercase sm:text-4xl xl:text-5xl text-medium-gray">
        {{ $t('not_found.description') }}
      </p>

      <p class="mt-4 uppercase text-fs-mid-blue font-bold">
        <a href="" @click.prevent="goBack">
          {{ $t('not_found.link') }}
        </a>
      </p>
    </div>
  </div>
</template>

<style scoped>
.background {
  background-image: url(~@/assets/images/svg/bg-bars.svg);
  background-repeat: repeat-y;
  background-position: center center;
  background-size: 100%;
}
</style>

<script>
export default {
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/')
    },
  },
}
</script>
