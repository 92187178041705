import itemPriceHistoryApi from '@/services/api/item-price-history'
import { uniqBy } from 'lodash'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  list: [],
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_ITEMS_DATA(state, { items, ...paginationMeta }) {
    state.list = uniqBy([...state.list, ...items], 'id')
    state.current_page += 1
    state.total_count = paginationMeta.total_count
  },
  RESET_LOADED_ITEMS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
}

export const actions = {
  filterItems({ commit }) {
    commit('RESET_LOADED_ITEMS')
  },
  async loadItems({ commit, state }, { infiniteLoaderContext, id }) {
    const { current_page, q } = state

    try {
      const response = await itemPriceHistoryApi.index(id, {
        page: current_page + 1,
        q,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_ITEMS_DATA', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
}
