import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/companies', { params })
  },
  create(params) {
    return apiClient.post('api/companies', params)
  },
  update(data) {
    return apiClient.put(`api/companies/${data.get('company_id')}`, data)
  },
  delete(id) {
    return apiClient.delete(`api/companies/${id}`)
  },
  attachments: {
    create(data) {
      return apiClient.post(
        `api/companies/${data.get('company_id')}/company_attachments`,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    },
    delete(id) {
      return apiClient.delete(`api/company_attachments/${id}`)
    },
  },
  integration_companies: {
    create(companyId, params) {
      return apiClient.post(
        `api/companies/${companyId}/selly_integration_companies`,
        params
      )
    },
  },
  pdf: {
    index(params) {
      return apiClient.get('api/companies.pdf', { params })
    },
  },
  user_list: {
    create(companyId, params) {
      return apiClient.post(`api/companies/${companyId}/user_list`, params)
    },
  },
  users: {
    delete(companyId, userId) {
      return apiClient.delete(`api/companies/${companyId}/users/${userId}`)
    },
    update(companyId, userId, params) {
      return apiClient.put(`api/companies/${companyId}/users/${userId}`, params)
    },
  },
  role_relations: {
    create(id, company_id, params) {
      return apiClient.post(
        `api/companies/${company_id}/users/${id}/role_relations`,
        params
      )
    },
    delete(userId, relationId, company_id) {
      return apiClient.delete(
        `api/companies/${company_id}/users/${userId}/role_relations/${relationId}`
      )
    },
    update(userId, relationId, company_id, params) {
      return apiClient.put(
        `api/companies/${company_id}/users/${userId}/role_relations/${relationId}`,
        params
      )
    },
  },
}
