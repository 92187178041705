import apiClient from '@/services/api'

export default {
  index(id, params) {
    return apiClient.get(`api/items/${id}/extract_bls`, { params })
  },
  assign: {
    create(id, params) {
      return apiClient.post(`api/items/${id}/extract_bls/assign`, params)
    },
  },
}
