import apiClient from '@/services/api'

export default {
  selly: {
    companies({ costCenterIds }) {
      return apiClient.get('api/integrations/selly/companies', {
        params: { cost_center_ids: costCenterIds },
      })
    },
    customers(supplierId, companyId) {
      return apiClient.get('api/integrations/selly/customers', {
        params: { supplier_id: supplierId, company_id: companyId },
      })
    },
    suppliers(companyId) {
      return apiClient.get('api/integrations/selly/suppliers', {
        params: { company_id: companyId },
      })
    },
    testBuyer(selly_ident_ekf) {
      return apiClient.put('api/integrations/selly/test_buyer', {
        selly_ident_ekf: selly_ident_ekf,
      })
    },
    unlockCustomer(supplierId, customerNumber, pinCode, companyId) {
      return apiClient.put('api/integrations/selly/unlock_customer', {
        supplier_id: supplierId,
        customer_number: customerNumber,
        pin_code: pinCode,
        company_id: companyId,
      })
    },
  },
  hogast: {
    authorize(username, password, companyId) {
      return apiClient.put('api/integrations/hogast/authorize', {
        username: username,
        password: password,
        company_id: companyId,
      })
    },
    companies({ costCenterIds }) {
      return apiClient.get('api/integrations/hogast/companies', {
        params: { cost_center_ids: costCenterIds },
      })
    },
    company_suppliers({ supplierTenantId }) {
      return apiClient.get('api/integrations/hogast/company_suppliers', {
        params: { supplier_tenant_id: supplierTenantId },
      })
    },
    suppliers({ companyId }) {
      return apiClient.get('api/integrations/hogast/suppliers', {
        params: { company_id: companyId },
      })
    },
  },
}
