import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/supplier_tenants', { params })
  },
  create(params) {
    return apiClient.post('api/supplier_tenants', params)
  },
  update(id, params) {
    return apiClient.put(`api/supplier_tenants/${id}`, params)
  },
  delete(id) {
    return apiClient.delete(`api/supplier_tenants/${id}`)
  },
  compared_attachment: {
    update(supplierId, params) {
      return apiClient.put(
        `api/supplier_tenants/${supplierId}/compared_attachment`,
        params,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    },
  },
  cost_center_prices: {
    create(supplierTenantId, params) {
      return apiClient.post(
        `api/supplier_tenants/${supplierTenantId}/cost_center_prices`,
        params
      )
    },
  },
  items_csv_attachments: {
    create(supplierId, params) {
      return apiClient.post(
        `api/supplier_tenants/${supplierId}/items_csv_attachments`,
        params,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    },
    show(id) {
      return apiClient.get(`api/items_csv_attachments/${id}`)
    },
    update(id, params) {
      return apiClient.put(`api/items_csv_attachments/${id}`, params)
    },
    draft_items: {
      update(id, params) {
        return apiClient.put(`api/draft_items/${id}`, { item: params })
      },
    },
  },
  item_list: {
    create(supplierId, params) {
      return apiClient.post(
        `api/supplier_tenants/${supplierId}/item_list`,
        params
      )
    },
  },
  pdf: {
    index(params) {
      return apiClient.get('api/supplier_tenants.pdf', {
        params,
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
    },
  },
  addresses: {
    create(supplierTenantId, params) {
      return apiClient.post(
        `api/supplier_tenants/${supplierTenantId}/addresses`,
        params
      )
    },
    update(supplierTenantId, params) {
      return apiClient.put(
        `api/supplier_tenants/${supplierTenantId}/addresses/${params.id}`,
        params
      )
    },
    delete(supplierTenantId, params) {
      return apiClient.delete(
        `api/supplier_tenants/${supplierTenantId}/addresses/${params.id}`
      )
    },
  },
  contacts: {
    create(supplierTenantId, params) {
      return apiClient.post(
        `api/supplier_tenants/${supplierTenantId}/contacts`,
        params
      )
    },
    update(supplierTenantId, params) {
      return apiClient.put(
        `api/supplier_tenants/${supplierTenantId}/contacts/${params.id}`,
        params
      )
    },
    delete(supplierTenantId, params) {
      return apiClient.delete(
        `api/supplier_tenants/${supplierTenantId}/contacts/${params.id}`
      )
    },
  },
  deliveryDays: {
    index(id) {
      return apiClient.get(`api/supplier_tenants/${id}/delivery_days`)
    },
  },
  integration_syncs: {
    create({ supplierTenantId }) {
      return apiClient.post(
        `api/supplier_tenants/${supplierTenantId}/integration_syncs`,
        {}
      )
    },
  },
  reset_base_units: {
    create(supplierTenantId) {
      return apiClient.post(
        `api/supplier_tenants/${supplierTenantId}/reset_base_units`
      )
    },
  },
}
