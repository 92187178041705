<template>
  <span :class="`icon-${name} text-${color} text-${size} font-regular`"></span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      require: true,
    },
    color: {
      type: [String, Object],
      default: 'medium-gray',
    },
    size: {
      type: String,
      default: 'xl',
    },
  },
}
</script>
