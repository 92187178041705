<template>
  <div @click="$emit('click')">
    <img
      v-if="isDarkMode || white"
      class="w-40"
      src="@/assets/images/svg/supply-one-logo-dark.svg"
      alt="Logo"
    />
    <img
      v-else
      class="w-40"
      src="@/assets/images/svg/supply-one-logo-light.svg"
      alt="Logo"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('currentUser', ['isDarkMode']),
  },
}
</script>
