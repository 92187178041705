import procurementsApi from '@/services/api/procurements'
import lookupsApi from '@/services/api/lookups'
import { sortBy, uniqBy } from 'lodash'
import dayjs from 'dayjs'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  list: [],
  statistics_data: {
    top_suppliers: [],
    max_item_amount: 0,
  },
  sort: {
    value: {
      name: 'item_statistics.most_bought',
      attr: '-ordered_amount',
    },
    options: [
      { name: 'item_statistics.most_bought', attr: '-ordered_amount' },
      { name: 'item_statistics.least_bought', attr: 'ordered_amount' },
    ],
    selected: false,
    type: 'radio',
  },
  filters: {
    suppliers: {
      value: [],
      loading: false,
      selected: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
    },
    date_range: {
      locale_code: 'item_statistics.date_range',
      start_at: null,
      end_at: null,
      type: 'dateRange',
    },
  },
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_ITEMS(state, { items, ...meta }) {
    state.list = uniqBy([...state.list, ...items], 'name')
    state.statistics_data = meta.statistics_data
    state.filters.date_range.start_at = dayjs(
      meta.statistics_data.start_at
    ).format('DD.MM.YYYY')
    state.filters.date_range.end_at = dayjs(meta.statistics_data.end_at).format(
      'DD.MM.YYYY'
    )
    state.current_page += 1
    state.total_count = meta.total_count
  },
  APPLY_DATE_RANGE_FILTER(state, { start, end }) {
    state.filters.date_range.start_at = start
    state.filters.date_range.end_at = end
  },
  APPLY_FILTER(state, { type, filterItem }) {
    if (
      filterItem.id &&
      state.filters[type].value.some((itm) => itm.id === filterItem.id)
    ) {
      state.filters[type].value = state.filters[type].value.filter(
        (existingItem) => {
          return existingItem.id !== filterItem.id
        }
      )
    } else {
      state.filters[type].value.push(filterItem)
    }
  },
  CLEAR_ALL_FILTERS(state) {
    state.filters.date_range.start_at = null
    state.filters.date_range.end_at = null
    state.filters.suppliers.value = []
  },
  CLEAR_ITEMS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
    state.statistics_data = {
      top_suppliers: [],
      max_item_amount: 0,
    }
  },
  SET_FILTER_LOADING(state, { value, type }) {
    state.filters[type].loading = value
  },
  SET_FILTER_OPTIONS(state, { data, type }) {
    state.filters[type].options = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        code: item.code,
        type,
      }
    })
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
  TOGGLE_MOBILE_SORT(state, item) {
    state.sort.selected = !item.selected
  },
}

export const actions = {
  applyDateRangeFilter({ commit }, { start, end }) {
    commit('APPLY_DATE_RANGE_FILTER', { start, end })
    commit('CLEAR_ITEMS')
  },
  applyFilter({ commit }, { filterItem, type }) {
    commit('APPLY_FILTER', { filterItem, type })
    commit('CLEAR_ITEMS')
  },
  clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS')
  },
  clearItems({ commit }) {
    commit('CLEAR_ITEMS')
  },
  async getItems({ commit, state }, { infiniteLoaderContext, period }) {
    const {
      current_page,
      filters: { suppliers, date_range },
      sort: { value },
    } = state

    try {
      const response = await procurementsApi.items.index({
        page: current_page + 1,
        period,
        supplier_tenant_ids: suppliers.value.map((item) => item.id).join(','),
        start_at: date_range.start_at,
        end_at: date_range.end_at,
        sort: value && value.attr,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_ITEMS', response.data)
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  async loadFilterOptions({ commit }, type) {
    commit('SET_FILTER_LOADING', { value: true, type })

    let options = []

    switch (type) {
      case 'suppliers': {
        const response = await lookupsApi.supplier_tenants.index({
          all: true,
        })
        options = response.data.supplier_tenants
        break
      }
    }

    commit('SET_FILTER_OPTIONS', {
      data: sortBy(options, (n) => n.name.toLowerCase(), 'name'),
      type,
    })

    commit('SET_FILTER_LOADING', { value: false, type })
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  toggleMobileSort({ commit }, sortState) {
    commit('TOGGLE_MOBILE_SORT', sortState)
  },
}

export const getters = {
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
}
