var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",class:{
    'w-26 h-26': !_vm.overlayed && _vm.size === 'lg',
    'w-10 h-10': !_vm.overlayed && _vm.size === 'md',
    'w-8 h-8': !_vm.overlayed && _vm.size === 'sm',
    'w-7 h-7': !_vm.overlayed && _vm.size === 'xxs',
    [_vm.overlayedClasses]: _vm.overlayed,
  }},[(_vm.user.avatar_url)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: `${_vm.user.first_name} ${_vm.user.last_name}`,
      placement: _vm.tooltipPlacement,
    }),expression:"{\n      content: `${user.first_name} ${user.last_name}`,\n      placement: tooltipPlacement,\n    }"}],staticClass:"object-cover rounded-full",class:{
      'w-7 h-7': _vm.size === 'xxs',
      'w-8 h-8': _vm.size === 'sm',
      'w-10 h-10': _vm.size === 'md',
      'w-26 h-26': _vm.size === 'lg',
      'md:w-8 md:h-8 md:text-sm': _vm.overlayed,
    },attrs:{"alt":"","src":_vm.user.avatar_url}}):(_vm.withMailIcon)?_c('div',{staticClass:"flex items-center justify-center w-8 h-8 rounded-full bg-silver md:w-8 md:h-8"},[_c('MailCircleIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.user.email),expression:"user.email"}],staticClass:"w-4 h-4 md:w-auto md:h-auto"})],1):_c('InitialsCircle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: `${_vm.user.first_name} ${_vm.user.last_name}`,
      placement: _vm.tooltipPlacement,
    }),expression:"{\n      content: `${user.first_name} ${user.last_name}`,\n      placement: tooltipPlacement,\n    }"}],staticClass:"inline-block object-cover text-sm",class:{
      'w-7 h-7 text-xxs': _vm.size === 'xxs',
      'w-8 h-8 text-sm': _vm.size === 'sm',
      'w-10 h-10': _vm.size === 'md',
      'w-26 h-26 text-xl': _vm.size === 'lg',
      'md:w-8 md:h-8 md:text-sm': _vm.overlayed,
    },attrs:{"color-code":_vm.user.avatar_background}},[_c('span',{class:`text-${_vm.initialsTextColor}`},[_vm._v(_vm._s(_vm.user.initials))])]),(_vm.withStatus)?_c('div',{staticClass:"absolute w-3.5 h-3.5 text-center rounded-full flex items-center justify-center",class:{
      [_vm.statusClass]: _vm.statusClass,
      'bg-s1-green-100': _vm.user.status === 'active',
      'bg-fs-mid-blue': _vm.user.status === 'invited',
      'bg-fs-red': _vm.user.status === 'locked',
      'bg-fs-light-coldgrey': _vm.user.status === 'offline',
      'top-2 right-2 w-4 h-4': _vm.size === 'lg',
      'top-0 -right-0.5': _vm.size === 'md' || _vm.size === 'sm',
      '-top-0.5 -right-0.5 left-5 bottom-5': _vm.size === 'xxs',
    }},[_c('div',{staticClass:"flex items-center justify-center w-full h-full"},[(_vm.user.status === 'invited')?_c('span',{staticClass:"flex justify-self-center self-center text-white text-sm"},[_c('SideNavIcon',{attrs:{"name":"mail","color":"alabaster","size":"xxs"}})],1):_vm._e(),(_vm.user.status === 'locked')?_c('ActionIcon',{staticClass:"flex",attrs:{"name":"actions_lock_outline","size":"xxs","color":"alabaster"}}):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }