<template>
  <div>
    <MinimalModalWrapper v-on="$listeners">
      <header>
        <div class="flex justify-between w-full p-4 text-base font-medium">
          <div>
            <h3>{{ $t('notifications.items_updated.title') }}</h3>
          </div>
          <ButtonClose class="flex-none -mt-1" @click="handleCancel" />
        </div>
      </header>

      <main>
        <div class="flex flex-wrap m-4">
          <p>{{ $t('notifications.items_updated.description') }}</p>
          <div>
            <ul class="list-disc mt-4 pl-6">
              <li>
                {{
                  $t('notifications.items_updated.prices_changed', {
                    count: statistic.updated_prices_count,
                    name: statistic.supplier_name,
                  })
                }}
              </li>
              <li>
                {{
                  $t('notifications.items_updated.cart_name_affected', {
                    name: statistic.affected_carts_names.join(', '),
                  })
                }}
              </li>
              <li>
                {{
                  $t('notifications.items_updated.items_no_longer_available', {
                    count: statistic.deleted_variants_count,
                  })
                }}
              </li>
            </ul>
          </div>
        </div>

        <div class="flex items-end flex-grow m-4 md:items-start md:flex-grow-0">
          <FormGroupBase class="w-1/2 mt-4 mr-2">
            <Button kind="secondary" type="button" @click="handleCancel">
              {{ $t('shared.dismiss') }}
            </Button>
          </FormGroupBase>

          <FormGroupBase class="w-1/2 mt-4">
            <Button type="submit" @click="handleContinue">
              <span>{{ $t('notifications.items_updated.view_carts') }}</span>
            </Button>
          </FormGroupBase>
        </div>
      </main>
    </MinimalModalWrapper>
  </div>
</template>

<script>
import Button from '@/components/shared/atoms/base-button'
import ButtonClose from '@/components/shared/atoms/button-close'
import FormGroupBase from '@/components/shared/atoms/base-form-group'
import MinimalModalWrapper from '@/components/shared/templates/minimal-modal-wrapper'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ConfirmationModal',
  components: {
    Button,
    ButtonClose,
    FormGroupBase,
    MinimalModalWrapper,
  },
  async created() {},
  data() {
    return {}
  },
  props: {},
  computed: {
    ...mapState(['currentUser']),
    statistic: function () {
      return this.currentUser.currentUser.notifications.items_updated_statistic
    },
  },
  methods: {
    ...mapActions('cart', { orderGetDeliveryDays: 'getDeliveryDays' }),
    ...mapActions('currentUser', ['clearNotification']),
    handleCancel() {
      this.clearNotification({ name: 'items_updated_statistic' })
    },
    handleContinue() {
      this.clearNotification({ name: 'items_updated_statistic' })
      this.$router.push('/cart')
    },
  },
}
</script>
