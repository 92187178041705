import integrationsApi from '@/services/api/integrations'

export const namespaced = true

export const state = {
  navigation: {
    company_id: null,
    supplier_tenant_id: null,
  },
}

export const mutations = {
  SET_NAVIGATION_COMPANY(state, { company_id }) {
    state.navigation.company_id = company_id
  },
  SET_NAVIGATION_SUPPLIER(state, { supplier_tenant_id }) {
    state.navigation.supplier_tenant_id = supplier_tenant_id
  },
}

export const actions = {
  async getHogastCompanies(_ctx, { costCenterIds }) {
    const response = await integrationsApi.hogast.companies({
      costCenterIds,
    })
    return response.data
  },
  async getHogastCompanySuppliers(_ctx, { supplierTenantId }) {
    const response = await integrationsApi.hogast.company_suppliers({
      supplierTenantId,
    })
    return response.data
  },
  async getHogastSuppliers(_ctx, { companyId }) {
    const response = await integrationsApi.hogast.suppliers({
      companyId,
    })
    return response.data.suppliers
  },
  async getSellyCompanies(_ctx, { costCenterIds }) {
    const response = await integrationsApi.selly.companies({
      costCenterIds,
    })
    return response.data
  },
  async getSellySuppliers(_ctx, { companyId }) {
    const response = await integrationsApi.selly.suppliers(companyId)
    return response.data.selly_suppliers
  },
  async getSellySupplierCustomers(_ctx, { supplierId, companyId }) {
    const response = await integrationsApi.selly.customers(
      supplierId,
      companyId
    )
    return response.data.selly_customers
  },
  async unlockSellyCustomer(
    _ctx,
    { supplierId, customerNumber, pinCode, companyId }
  ) {
    const response = await integrationsApi.selly.unlockCustomer(
      supplierId,
      customerNumber,
      pinCode,
      companyId
    )

    return response.data
  },
  async authorizeHogast(_ctx, { username, password, companyId }) {
    const response = await integrationsApi.hogast.authorize(
      username,
      password,
      companyId
    )
    return response.data
  },
  async testSellyBuyer(_ctx, { sellyIdentEKF }) {
    const response = await integrationsApi.selly.testBuyer(sellyIdentEKF)
    return response.data
  },
  setNavigationCompany({ commit }, { company_id }) {
    commit('SET_NAVIGATION_COMPANY', { company_id })
  },
  setNavigationSupplier({ commit }, { supplier_tenant_id }) {
    commit('SET_NAVIGATION_SUPPLIER', { supplier_tenant_id })
  },
}
export const getters = {}
