<template>
  <div class="fixed z-60 inset-0 overflow-y-auto h-full">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity"
        aria-hidden="true"
        @click.stop="$emit('close')"
      >
        <div class="absolute inset-0 bg-cod-gray opacity-75"></div>
      </div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="fixed w-full bottom-0 inline-block text-left shadow-xl transform transition-all md:align-middle md:static"
        :class="{
          'overflow-hidden': overflowed,
          [`md:w-${modalMdWidth}`]: !modalWidth,
          [modalBorderRadius]: modalBorderRadius && !modalTopBorderRadius,
          [`w-${modalWidth}`]: modalWidth,
          'rounded-t-lg': modalTopBorderRadius,
          'bg-s1-main-100': isNewBackground,
          'bg-default-bg': !isNewBackground,
        }"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isNewBackground: {
      type: Boolean,
      default: false,
    },
    overflowed: {
      type: Boolean,
      default: false,
    },
    modalBorderRadius: {
      type: String,
      default: 'rounded-lg',
    },
    modalTopBorderRadius: {
      type: Boolean,
      default: false,
    },
    modalWidth: {
      type: String,
      default: null,
    },
    modalMdWidth: {
      type: String,
      default: '400px',
    },
  },
}
</script>
