var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({staticClass:"focus:outline-none flex-shrink-0 text-sm text-backdrop font-regular",class:{
    [_vm.borderRadius]: _vm.borderRadius,
    [`w-${_vm.width}`]: _vm.width,
    [`h-${_vm.height}`]: true,
    [`px-${_vm.padding}`]: true,
    'bg-s1-blue-100 hover:bg-s1-blue-darker text-s1-main-100':
      _vm.kind === 'primary',
    'bg-s1-main-100 hover:bg-s1-blue-20 border border-s1-blue-100 hover:border-s1-blue-darker text-s1-blue-100 hover:text-s1-blue-darker':
      _vm.kind === 'secondary',
    'opacity-50 cursor-auto pointer-events-none': _vm.disabled,
  },attrs:{"disabled":_vm.disabled || _vm.loading}},'button',_vm.$attrs,false),_vm.$listeners),[_c('span',{staticClass:"font-medium text-sm leading-3"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }