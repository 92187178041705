import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/users', { params })
  },
  update(id, params) {
    return apiClient.put(`api/users/${id}`, params)
  },
  delete(id) {
    return apiClient.delete(`api/users/${id}`)
  },
  invitations: {
    update(userId) {
      return apiClient.put(`api/users/${userId}/invitation`)
    },
  },
  password: {
    create(params) {
      return apiClient.post('api/users/passwords', params)
    },
    update(token, params) {
      return apiClient.put(`api/users/passwords/${token}`, params)
    },
    show(token) {
      return apiClient.get(`api/users/passwords/${token}`)
    },
  },
  role_relations: {
    create(id, params) {
      return apiClient.post(`api/users/${id}/role_relations`, params)
    },
    delete(userId, relationId) {
      return apiClient.delete(
        `api/users/${userId}/role_relations/${relationId}`
      )
    },
    update(userId, relationId, params) {
      return apiClient.put(
        `api/users/${userId}/role_relations/${relationId}`,
        params
      )
    },
  },
  versions: {
    index(id, params) {
      return apiClient.get(`api/users/${id}/versions`, { params })
    },
  },
  pdf: {
    index(params) {
      return apiClient.get('api/users.pdf', { params })
    },
  },
}
