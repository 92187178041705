import deliveriesApi from '@/services/api/deliveries'
import returnReasonsApi from '@/services/api/return-reasons'
import { uniqBy, unionBy } from 'lodash'

export const namespaced = true

export const state = {
  loading: false,
  defective_items: [],
  resource: null,
  current_order: null,
  current_inventory_unit: null,
  current_return_authorization: null,
  selected_attachment: null,
  versions: {
    count: 0,
    total_count: 0,
    current_page: 0,
    pages: 0,
    per_page: 0,
    infiniteId: +new Date(),
    list: [],
  },
}

export const mutations = {
  ADD_ADDITIONAL_ITEM(state, item) {
    const addedItem = state.current_order.additional_items.find(
      (i) => i.id === item.id
    )

    if (addedItem) {
      addedItem.quantity++
    } else {
      const newItem = Object.assign({}, item)

      newItem.quantity = 1
      newItem.type = 'additional_items'
      state.current_order.additional_items = uniqBy(
        [...state.current_order.additional_items, ...[newItem]],
        'id'
      )
    }
  },
  ADD_RETURN_ITEM(state, item) {
    const addedItem = state.current_order.return_items.find(
      (i) => i.id === item.id
    )

    if (addedItem) {
      addedItem.quantity++
    } else {
      const newItem = Object.assign({}, item)

      newItem.quantity = 1
      newItem.type = 'return_items'
      state.current_order.return_items = uniqBy(
        [...state.current_order.return_items, ...[newItem]],
        'id'
      )
    }
  },
  CLEAR_CURRENT_RETURN_AUTHOIZATION(state) {
    state.current_return_authorization = null
  },
  CONFIRM_DEFECTIVE_ITEM(state, { params, item }) {
    state.defective_items = unionBy(
      [params],
      state.defective_items,
      'line_item_id'
    )

    if (item.line_item_id) {
      item.marked_as_defective_quantity =
        params.quantity - item.defective_packaging_quantity
    } else {
      item.marked_as_defective_quantity = params.quantity
    }
  },
  DECREASE_ADDITIONAL_ITEM(state, item) {
    state.current_order.additional_items =
      state.current_order.additional_items.filter((additionalItem) => {
        if (additionalItem.id === item.id) {
          additionalItem.quantity--
        }

        return additionalItem.quantity > 0
      })
  },
  DECREASE_RETURN_ITEM(state, item) {
    state.current_order.return_items = state.current_order.return_items.filter(
      (returnItem) => {
        if (returnItem.id === item.id) {
          returnItem.quantity--
        }

        return returnItem.quantity > 0
      }
    )
  },
  SET_CURRENT_RETURN_AUTHORIZATION(state, returnAuthorization) {
    state.current_return_authorization = returnAuthorization
  },
  SET_CURRENT_INVENTORY_UNIT(state, item) {
    state.current_inventory_unit = item
  },
  SET_DELIVERY(state, delivery) {
    state.resource = delivery.delivery
    state.current_order = delivery.order
  },
  SET_DELIVERY_ATTACHMENT(state, delivery_attachments) {
    state.resource.delivery_attachments = delivery_attachments
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_SELECTED_ATTACHMENT(state, attachment) {
    state.selected_attachment = attachment
  },
  UPDATE_ADDITIONAL_ITEM_QTY(state, { item, qty }) {
    if (isNaN(qty)) return

    state.current_order.additional_items =
      state.current_order.additional_items.filter((returnItem) => {
        if (returnItem.id === item.id) {
          returnItem.quantity = qty
        }

        return returnItem.quantity > 0
      })
  },
  UPDATE_DELIVERY(state, delivery) {
    state.resource = delivery
    state.defective_items = []
  },
  UPDATE_RETURN_ITEM_QTY(state, { item, qty }) {
    if (isNaN(qty)) return

    state.current_order.return_items = state.current_order.return_items.filter(
      (returnItem) => {
        if (returnItem.id === item.id) {
          returnItem.quantity = qty
        }

        return returnItem.quantity > 0
      }
    )
  },
  ADD_DELIVERY_VERSIONS_DATA(state, { versions, ...paginationMeta }) {
    state.versions.list = [...state.versions.list, ...versions]
    state.versions.current_page += 1
    state.versions.total_count = paginationMeta.total_count
  },
}

export const actions = {
  addAdditionalItem({ commit }, item) {
    commit('ADD_ADDITIONAL_ITEM', item)
  },
  addReturnItem({ commit }, item) {
    commit('ADD_RETURN_ITEM', item)
  },
  clearCurrentReturnAuthorization({ commit }) {
    commit('CLEAR_CURRENT_RETURN_AUTHOIZATION')
  },
  confirmDefectiveItem({ commit }, { params, item }) {
    commit('CONFIRM_DEFECTIVE_ITEM', { params, item })
  },
  decreaseAdditionalItem({ commit }, item) {
    commit('DECREASE_ADDITIONAL_ITEM', item)
  },
  decreaseReturnItem({ commit }, item) {
    commit('DECREASE_RETURN_ITEM', item)
  },
  async getDelivery({ commit }, number) {
    const response = await deliveriesApi.show(number)

    commit('SET_DELIVERY', response.data)
  },
  async getInventoryUnit({ commit }, id) {
    const response = await deliveriesApi.inventory_units.show(id)

    commit('SET_CURRENT_INVENTORY_UNIT', response.data)

    if (response.data.return_items[0]) {
      commit(
        'SET_CURRENT_RETURN_AUTHORIZATION',
        response.data.return_items[0].return_authorization
      )
    }
  },
  async getReturnReasons() {
    return await returnReasonsApi.index()
  },
  async updateDeliveryAttachment({ commit }, formData) {
    commit('SET_LOADING', true)
    const response = await deliveriesApi.attachment.create(formData)
    commit('SET_DELIVERY_ATTACHMENT', response.data.delivery_attachments)
    commit('SET_LOADING', false)
  },
  async updateReturnAuthorization(
    { commit },
    { returnAuthorizationId, params }
  ) {
    commit('SET_LOADING', true)

    await deliveriesApi.inventory_units.return_authorizations.update(
      returnAuthorizationId,
      params
    )

    commit('SET_LOADING', false)
  },
  async deleteDeliveryAttachment({ commit }, params) {
    const response = await deliveriesApi.attachment.delete(params)

    commit('SET_DELIVERY_ATTACHMENT', response.data.delivery_attachments)
  },
  selectDeliveryAttachment({ commit }, attachment) {
    commit('SET_SELECTED_ATTACHMENT', attachment)
  },
  setLoading({ commit }, value) {
    commit('SET_LOADING', value)
  },
  async uploadReturnAuthorizationImages({ commit }, formData) {
    commit('SET_LOADING', true)

    const response =
      await deliveriesApi.inventory_units.return_authorization_images.create(
        formData
      )

    commit('SET_CURRENT_RETURN_AUTHORIZATION', response.data)
    commit('SET_LOADING', false)
  },
  updateAdditionalItemQty({ commit }, { item, qty }) {
    commit('UPDATE_ADDITIONAL_ITEM_QTY', { item, qty })
  },
  async updateDelivery({ commit }, formData) {
    commit('SET_LOADING', true)
    const response = await deliveriesApi.update(formData)

    commit('UPDATE_DELIVERY', response.data.delivery)
    commit('SET_LOADING', false)
  },
  updateReturnItemQty({ commit }, { item, qty }) {
    commit('UPDATE_RETURN_ITEM_QTY', { item, qty })
  },
  async loadDeliveryVersions({ commit, state }, params) {
    const { current_page } = state.versions
    const { paginationContext, number } = params

    try {
      const response = await deliveriesApi.versions.index(number, {
        page: current_page + 1,
      })

      if (response.data.pages <= current_page) {
        paginationContext.complete()
      } else {
        paginationContext.loaded()
      }

      commit('ADD_DELIVERY_VERSIONS_DATA', response.data)
    } catch (_error) {
      paginationContext.error()
    }
  },
}

export const getters = {
  isReturnAuthorizationCreated: (state) => {
    if (state.current_inventory_unit) {
      return state.current_inventory_unit.return_items.length > 0
    }
  },
}
