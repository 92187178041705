export const THEME_COLORS = {
  's1-blue-100': '#3190ff',
  's1-blue-darker': '#0075ff',
  's1-gray-80-light': '#6f767e',
  's1-gray-20-light': '#f4f4f4',
  's1-red-100': '#ff6a55',
  's1-purple-100': '#cabdff',
  's1-blue-secondary-100': '#b1e5fc',
  's1-green-100': '#b5e4ca',
  's1-green-50': '#daf2e5',
  's1-yellow-100': '#ffd88d',
  's1-yellow-50': '#ffecc6',
  's1-orange-100': '#ffbc99',
  's1-orange-50': '#ffddcc',

  LIGHT: {
    's1-blue-60': '#83bcff',
    's1-blue-50': '#aed9ff',
    's1-blue-20': '#dfefff',
    's1-blue-10': '#eff8ff',
    's1-main-100': '#ffffff',
    's1-gray-100': '#373740',
    's1-gray-80': '#6f767e',
    's1-gray-60': '#9b9a9d',
    's1-gray-50': '#dcdedf',
    's1-gray-20': '#f4f4f4',
  },
  DARK: {
    's1-blue-60': '#2762a5',
    's1-blue-50': '#25578f',
    's1-blue-20': '#1f344c',
    's1-blue-10': '#1c2835',
    's1-main-100': '#1a1d1f',
    's1-gray-100': '#fcfcfc',
    's1-gray-80': '#d1d2d2',
    's1-gray-60': '#a1a3a3',
    's1-gray-50': '#88898a',
    's1-gray-20': '#111315',
  },
}
