<template>
  <PulseLoader :color="color" :size="size" />
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/../tailwind.config.js'

export default {
  props: {
    size: {
      type: String,
      default: '5px',
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PulseLoader,
  },
  computed: {
    theme() {
      return resolveConfig(tailwindConfig).theme
    },
    color() {
      return this.button
        ? this.theme.colors['s1-main-100']
        : this.theme.colors['s1-blue-100']
    },
  },
}
</script>
