export default {
  getAccessToken() {
    return localStorage.getItem('ACCESS_TOKEN')
  },
  setAccessToken(token) {
    localStorage.setItem('ACCESS_TOKEN', token)
  },
  getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN')
  },
  setRefreshToken(token) {
    localStorage.setItem('REFRESH_TOKEN', token)
  },
  removeAccessToken() {
    localStorage.removeItem('ACCESS_TOKEN')
  },
  removeRefreshToken() {
    localStorage.removeItem('REFRESH_TOKEN')
  },
}
