import { uniqBy } from 'lodash'

import accountsApi from '@/services/api/accounts'

export const namespaced = true

export const state = {
  list: [],
  current_page: 1,
  infiniteId: +new Date(),
  pages: 0,
  per_page: 0,
  q: null,
  total_count: 0,
  filters: {
    account_plans: {
      value: [],
      options: [
        { name: 'free', type: 'account_plans' },
        { name: 'business', type: 'account_plans' },
        { name: 'enterprise', type: 'account_plans' },
        { name: 'infinite', type: 'account_plans' },
        { name: 'demo', type: 'account_plans' },
      ],
      type: 'checkbox',
      selected: false,
    },
  },
}

export const mutations = {
  ADD_ACCOUNTS(state, data) {
    state.total_count = data.total_count
    state.list = uniqBy([...state.list, ...data.accounts], 'id')
  },
  APPLY_FILTER(state, { filterItem, type }) {
    switch (type) {
      case 'checkbox':
        if (
          state.filters[filterItem.type].value.some(
            (item) => item.name === filterItem.name
          )
        ) {
          state.filters[filterItem.type].value = state.filters[
            filterItem.type
          ].value.filter((existingItem) => {
            return existingItem.name !== filterItem.name
          })
        } else {
          state.filters[filterItem.type].value.push(filterItem)
        }
        break
      case 'radio':
        state.filters[filterItem.type].value = filterItem
        break
      default:
        throw 'Undefined filter type'
    }
  },
  REMOVE_FILTER(state, filterOption) {
    state.filters[filterOption.type].value = state.filters[
      filterOption.type
    ].value.filter((option) => option.name !== filterOption.name)
  },
  REMOVE_FILTERS(state) {
    state.filters.account_plans.value = []
  },
  RESET_ACCOUNTS(state) {
    state.grouped_suborders_list = {}
    state.list = []
    state.current_page = 1
    state.infiniteId += 1
  },
  SET_ACCOUNT(state, { account }) {
    state.list = state.list.filter((acc) => acc.id !== account.id)
    state.list = [...state.list, account]
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
}

export const getters = {
  appliedFilters: (state) => {
    const { filters } = state

    return [...filters.account_plans.value].filter(
      (filterItem) => filterItem && filterItem.name
    )
  },
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
}

export const actions = {
  applyFilter({ commit }, { filterItem, type }) {
    commit('APPLY_FILTER', { filterItem, type })
    commit('RESET_ACCOUNTS')
  },
  async createAccount({ commit }, params) {
    const response = await accountsApi.create(params)

    commit('REMOVE_FILTERS')
    commit('RESET_ACCOUNTS')

    return response
  },
  async loadMore({ state, commit }, infiniteLoaderContext) {
    const { current_page, filters } = state

    try {
      const response = await accountsApi.index({
        q: state.q,
        page: current_page,
        account_plans: filters.account_plans.value
          .map((item) => item.name)
          .join(','),
      })

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        state.current_page += 1
        infiniteLoaderContext.loaded()
      }

      commit('ADD_ACCOUNTS', response.data)
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  async lockAccount({ commit }, params) {
    const response = await accountsApi.update(params)

    commit('SET_ACCOUNT', response.data)
  },
  removeFilter({ commit }, filterOption) {
    commit('REMOVE_FILTER', filterOption)
    commit('RESET_ACCOUNTS')
  },
  removeFilters({ commit }) {
    commit('REMOVE_FILTERS')
    commit('RESET_ACCOUNTS')
  },
  resetLoaded({ commit }) {
    commit('RESET_ACCOUNTS')
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
}
