import apiClient from '@/services/api'

export default {
  create(params) {
    return apiClient.post('api/cost_centers', params)
  },
  index(params) {
    return apiClient.get('api/cost_centers', { params })
  },
  update(data) {
    return apiClient.put(`api/cost_centers/${data.get('cost_center_id')}`, data)
  },
  delete(id) {
    return apiClient.delete(`api/cost_centers/${id}`)
  },
  attachments: {
    create(data) {
      return apiClient.post(
        `api/cost_centers/${data.get(
          'cost_center_id'
        )}/cost_center_attachments`,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    },
    delete(id) {
      return apiClient.delete(`api/cost_center_attachments/${id}`)
    },
  },
  users: {
    delete(costCenterId, userId) {
      return apiClient.delete(
        `api/cost_centers/${costCenterId}/users/${userId}`
      )
    },
    update(costCenterId, userId, params) {
      return apiClient.put(
        `api/cost_centers/${costCenterId}/users/${userId}`,
        params
      )
    },
  },
  user_list: {
    create(costCenterId, params) {
      return apiClient.post(
        `api/cost_centers/${costCenterId}/user_list`,
        params
      )
    },
  },
}
