import apiClient from '@/services/api'

export default {
  update(params) {
    return apiClient.put('api/current_user/profile', params)
  },
  avatar: {
    update(params) {
      return apiClient.put('api/current_user/avatar', params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    },
  },
  smtp_setting: {
    update(params) {
      return apiClient.put('api/current_user/smtp_setting', params)
    },
    show() {
      return apiClient.get('api/current_user/smtp_setting')
    },
    test_email: {
      create() {
        return apiClient.post('api/current_user/smtp_setting/test_email')
      },
    },
  },
}
