import itemsCsvAttachmentApi from '@/services/api/items-csv-attachment'
import itemsImportsApi from '@/services/api/items-imports'
import { uniqBy, isEmpty } from 'lodash'

export const namespaced = true

export const state = {
  list: [],
  suppliers: [],
  isReverting: false,
  count: 0,
  total_count: 0,
  current_page: 0,
  filters: {
    suppliers: {
      value: [],
      options: [],
      type: 'checkbox',
      selected: false,
      withAsyncOptions: true,
    },
  },
  pages: 0,
  per_page: 0,
  q: null,
  infiniteId: +new Date(),
  isBlankState: null,
}

export const mutations = {
  APPLY_FILTER(state, { type, item }) {
    if (state.filters[type].value.some((itm) => item.id === itm.id)) {
      state.filters.suppliers.value = state.filters.suppliers.value.filter(
        (existingItem) => {
          return existingItem.id !== item.id
        }
      )
    } else {
      state.filters[type].value.push(item)
    }
  },
  SET_IMPORTS_DATA(
    state,
    { data: { items_imports, ...paginationMeta }, noFilters }
  ) {
    state.list = uniqBy([...state.list, ...items_imports], 'id')
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.isBlankState = noFilters && paginationMeta.total_count === 0
  },
  SET_FILTER_OPTIONS(state, { type, data }) {
    state.filters[type].options = data.suppliers.map((item) => {
      return {
        id: item.id,
        name: item.name,
        type,
      }
    })
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
  CLEAR_IMPORTS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  CLEAR_ALL_FILTERS(state) {
    state.filters.suppliers.value = []
  },
  CLEAR_FILTER(state, filterItem) {
    state.filters[filterItem.type].value = state.filters[
      filterItem.type
    ].value.filter((item) => {
      return item.name !== filterItem.name
    })
  },
  SET_REVERTING(state, value) {
    state.isReverting = value
  },
  UPDATE_ITEM_CSV_ATTACHMENT(state, { id, status }) {
    const itemsCsvAttachment = state.list.find(
      (item) => item.is_csv_import && item.source.id === id
    )
    if (!itemsCsvAttachment) return

    itemsCsvAttachment.source.status = status
  },
}

export const actions = {
  clearImportsHistory({ commit }) {
    commit('CLEAR_IMPORTS')
  },
  clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS')
  },
  clearFilter({ commit }, filterItem) {
    commit('CLEAR_FILTER', filterItem)
    commit('CLEAR_IMPORTS')
  },
  async loadImportsHistoryItems(
    { commit, state, getters },
    infiniteLoaderContext
  ) {
    const { current_page } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await itemsImportsApi.index({
        page: current_page + 1,
        q: state.q,
        supplier_ids: state.filters.suppliers.value.map((item) => item.id),
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('SET_FILTER_OPTIONS', {
        data: response.data,
        type: 'suppliers',
      })
      commit('SET_IMPORTS_DATA', {
        data: response.data,
        noFilters: isEmpty(state.q) && isEmpty(getters.appliedFilters),
      })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  applyFilter({ commit }, { type, item }) {
    commit('APPLY_FILTER', { type, item })
    commit('CLEAR_IMPORTS')
  },
  async revertImport({ commit }, id) {
    const response = await itemsCsvAttachmentApi.revert.create(id)

    commit('UPDATE_ITEM_CSV_ATTACHMENT', response.data)
  },
  setReverting({ commit }, value) {
    commit('SET_REVERTING', value)
  },
}

export const getters = {
  appliedFilters: (state) => {
    const { filters } = state

    return [...filters.suppliers.value].filter(
      (filterItem) => filterItem && filterItem.name
    )
  },
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
}
