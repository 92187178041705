import itemExtractBlsApi from '@/services/api/item-extract-bls'
import { uniqBy } from 'lodash'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  list: [],
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_EXTRACTED_BLS_GROUPS_DATA(
    state,
    { bls_group_variants, ...paginationMeta }
  ) {
    state.list = uniqBy([...state.list, ...bls_group_variants], 'id')
    state.current_page += 1
    state.total_count = paginationMeta.total_count
  },
  RESET_LOADED_EXTRACTED_BLS_GROUPS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
}

export const actions = {
  filterExtractedBlsGroups({ commit }) {
    commit('RESET_LOADED_EXTRACTED_BLS_GROUPS')
  },
  async getExtractedBlsGroups(
    { commit, state },
    { infiniteLoaderContext, id, forceExtract = false }
  ) {
    const { current_page, q } = state

    try {
      const response = await itemExtractBlsApi.index(id, {
        page: current_page + 1,
        q,
        force_extract: forceExtract,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_EXTRACTED_BLS_GROUPS_DATA', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
}
