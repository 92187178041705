import suppliers from '@/services/api/suppliers'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  global_count: null,
  cost_center_options: [],
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  selected_supplier: {},
  list: [],
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_SUPPLIERS_DATA(state, suppliers) {
    state.list = suppliers
  },
  RESET_SEARCH(state) {
    state.q = null
  },
  SELECT_SUPPLIER(state, supplier) {
    state.selected_supplier = supplier
  },
}

export const actions = {
  async addSupplier(_ctx, { id, ...params }) {
    await suppliers.update(id, params)
  },
  async loadSuppliers({ commit, state }) {
    const response = await suppliers.index({
      q: state.q,
    })

    commit('ADD_SUPPLIERS_DATA', response.data.suppliers)
  },
  resetSearch({ commit }) {
    commit('RESET_SEARCH')
  },
  selectSupplier({ commit }, supplier) {
    commit('SELECT_SUPPLIER', supplier)
  },
}
