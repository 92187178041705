import { append, ifElse, includes, without } from 'ramda'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import costCentersApi from '@/services/api/cost-centers'
import dayjs from 'dayjs'
import { groupBy, uniqBy, isEmpty } from 'lodash'
import ordersApi from '@/services/api/orders'
import supplierTenantsApi from '@/services/api/supplier-tenants'
import Vue from 'vue'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(customParseFormat)

const userPreference = JSON.parse(
  localStorage.getItem('currentUser')
)?.preference

const defaultGroupedValue =
  userPreference?.orders_grouped_by === 'ungrouped'
    ? { name: 'ungrouped', type: 'group' }
    : { name: userPreference?.orders_grouped_by, type: 'group' }

export const namespaced = true

export const state = {
  expanded: [],
  list: [],
  grouped_list: {},
  draft_list: [],
  totals: [],
  count: 0,
  current_page: 0,
  selected_attachment: null,
  current_sub_order: null,
  filters: {
    order_date: {
      start_at: null,
      end_at: null,
      type: 'dateRange',
      locale_code: 'filters.items.order_date',
    },
    delivery_date: {
      start_at: null,
      end_at: null,
      type: 'dateRange',
      locale_code: 'filters.items.delivery_date',
    },
    cost_centers: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      selected: false,
      withAsyncOptions: true,
    },
    state: {
      value: [],
      options: [
        { name: 'canceled', type: 'state' },
        { name: 'partially_delivered', type: 'state' },
        { name: 'delivered', type: 'state' },
        { name: 'draft', type: 'state' },
        { name: 'ordered', type: 'state' },
        { name: 'paid', type: 'state' },
      ],
      type: 'checkbox',
      selected: false,
    },
    suppliers: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      selected: false,
      withAsyncOptions: true,
    },
    group: {
      value: defaultGroupedValue,
      options: [
        { name: 'ungrouped', type: 'group' },
        { name: 'day', type: 'group' },
        { name: 'week', type: 'group' },
        { name: 'month', type: 'group' },
        { name: 'master_order', type: 'group' },
      ],
      type: 'radio',
      selected: false,
    },
  },
  sort: {
    value: {
      name: 'created_at_desc',
      attr: '-created_at',
      group_attr: '-created_at',
    },
    options: [
      {
        name: 'created_at_asc',
        attr: 'created_at',
        group_attr: 'created_at',
      },
      {
        name: 'created_at_desc',
        attr: '-created_at',
        group_attr: '-created_at',
      },
      {
        name: 'id_asc',
        attr: 'id',
        group_attr: 'id',
      },
      {
        name: 'id_desc',
        attr: '-id',
        group_attr: '-id',
      },
      {
        name: 'total_asc',
        attr: 'sum(total)',
        group_attr: 'total',
      },
      {
        name: 'total_desc',
        attr: '-sum(total)',
        group_attr: '-total',
      },
    ],
  },
  infiniteId: +new Date(),
  pages: 0,
  per_page: 0,
  q: null,
  total_count: 0,
  versions: {
    infiniteId: +new Date(),
    list: [],
    pages: 0,
    per_page: 0,
    total_count: 0,
    count: 0,
    current_page: 0,
  },
  sharedParams: {
    index: {},
  },
  isBlankState: null,
}

export const mutations = {
  ADD_ORDER_VERSIONS_DATA(state, { versions, ...paginationMeta }) {
    state.versions.list = [...state.versions.list, ...versions]
    state.versions.current_page += 1
    state.versions.total_count = paginationMeta.total_count
  },
  ADD_ORDERS(state, { data: { orders, ...paginationMeta }, noFilters }) {
    state.list = uniqBy([...state.list, ...orders], 'id')
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.isBlankState = noFilters && paginationMeta.total_count === 0
  },
  ADD_TOTALS(state, { totals }) {
    state.totals = totals
  },
  APPLY_FILTER(state, { type, item }) {
    if (type === 'group') {
      state.filters.group.value = item
      return
    }

    if (state.filters[type].value.includes(item)) {
      state.filters[type].value = state.filters[type].value.filter(
        (existingItem) => {
          return existingItem !== item
        }
      )
    } else {
      state.filters[type].value.push(item)
    }
  },
  CLEAR_ALL_FILTERS(state) {
    const { filters } = state

    filters.delivery_date.start_at = null
    filters.delivery_date.end_at = null
    filters.order_date.start_at = null
    filters.order_date.end_at = null
    filters.state.value = []
    filters.suppliers.value = []
    filters.cost_centers.value = []
    filters.group.value = defaultGroupedValue
  },
  CLEAR_FILTER(state, filterItem) {
    if (filterItem.type === 'delivery_date') {
      state.filters.delivery_date.start_at = null
      state.filters.delivery_date.end_at = null

      return
    } else if (filterItem.type === 'order_date') {
      state.filters.order_date.start_at = null
      state.filters.order_date.end_at = null

      return
    } else if (filterItem.type === 'group') {
      state.filters.group.value = defaultGroupedValue

      return
    } else {
      state.filters[filterItem.type].value = state.filters[
        filterItem.type
      ].value.filter((item) => {
        return item.name !== filterItem.name
      })
    }
  },
  CLEAR_ORDERS(state) {
    state.list = []
    state.grouped_list = {}
    state.draft_list = []
    state.current_page = 0
    state.infiniteId += 1
    state.totals = []
  },
  ADD_GROUPED_ORDERS(state, data) {
    state.list = []
    state.current_page += 1
    state.total_count = data.total_count
    state.draft_list = uniqBy([...state.draft_list, ...data.draft_orders], 'id')

    if (!data.grouped_orders) return

    Object.entries(data.grouped_orders).forEach(([date, params]) => {
      if (state.grouped_list[date]) {
        params.master_orders.forEach((masterOrder) => {
          const foundOrder = state.grouped_list[date].master_orders.find(
            (existingOrder) => existingOrder.id === masterOrder.id
          )

          if (foundOrder) {
            foundOrder.sub_orders = uniqBy(
              [...foundOrder.sub_orders, ...masterOrder.sub_orders],
              'id'
            )
          } else {
            state.grouped_list[date].master_orders.push(masterOrder)
          }
        })
      } else {
        Vue.set(state.grouped_list, date, params)
      }
    })
  },
  DELETE_ORDER(state, order) {
    state.list = state.list.filter((o) => o.id !== order.id)
    state.total_count = state.total_count - 1
  },
  REPLACE_ORDER(state, order) {
    state.list = state.list.map((existingOrder) =>
      existingOrder.id === order.id ? order : existingOrder
    )
  },
  RESET_ORDER_VERSIONS(state) {
    state.versions.list = []
    state.versions.current_page = 0
    state.versions.total_count = 0
  },
  CHANGE_ORDER_STATE(state, { id, status }) {
    const order = state.list.find((o) => o.id === id)
    order.full_supply_state = status
  },
  SET_DELIVERY_DATE_RANGE(state, { start, end }) {
    state.filters.delivery_date.start_at = start
    state.filters.delivery_date.end_at = end
  },
  SET_ORDER_DATE_RANGE(state, { start, end }) {
    state.filters.order_date.start_at = start
    state.filters.order_date.end_at = end
  },
  SET_CURRENT_SUBORDER(state, subOrder) {
    state.current_sub_order = subOrder
  },
  SET_FILTER_LOADING(state, { type, value }) {
    state.filters[type].loading = value
  },
  SET_COST_CENTER_FILTER_OPTIONS(state, options) {
    state.filters.cost_centers.options = options.map((option) => {
      return { type: 'cost_centers', ...option }
    })
  },
  SET_FILTER_OPTIONS(state, { type, data }) {
    state.filters[type].options = data.suppliers.map((item) => {
      return {
        id: item.id,
        name: item.name,
        type,
      }
    })
  },
  SET_SELECTED_ATTACHMENT(state, attachment) {
    state.selected_attachment = attachment
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected

    if (filterItem.name === 'suppliers') {
      state.filters[filterItem.name].loading = true
    }
  },
  TOGGLE_ORDER_EXPAND(state, orderId) {
    state.expanded = ifElse(
      includes(orderId),
      without([orderId]),
      append(orderId)
    )(state.expanded)
  },
  UPDATE_ORDERS_LIST(state) {
    state.list = [...state.list]
  },
  SET_SHARED_INDEX_PARAMS(state) {
    state.sharedParams.index = {
      q: state.q,
      state: state.filters.state.value.map((item) => item.name).join(','),
      delivery_start_at: state.filters.delivery_date.start_at,
      delivery_end_at: state.filters.delivery_date.end_at,
      order_start_at: state.filters.order_date.start_at,
      order_end_at: state.filters.order_date.end_at,
      suppliers: state.filters.suppliers.value.map((item) => item.id).join(','),
      cost_centers: state.filters.cost_centers.value.map((item) => item.id),
    }
  },
}

export const actions = {
  applyFilter({ commit }, { type, item }) {
    commit('APPLY_FILTER', { type, item })
    commit('CLEAR_ORDERS')
  },
  async cancelSubOrder({ commit }, { order, send_order_cancelation_email }) {
    const response = await ordersApi.cancel.update(order.id, {
      canceled: true,
      send_order_cancelation_email: send_order_cancelation_email,
    })

    commit('CHANGE_ORDER_STATE', {
      id: response.data.id,
      status: response.data.full_supply_state,
    })
  },
  async unCancelSubOrder({ commit }, { order, send_order_cancelation_email }) {
    const response = await ordersApi.cancel.update(order.id, {
      send_order_cancelation_email: send_order_cancelation_email,
      canceled: false,
    })
    commit('CHANGE_ORDER_STATE', {
      id: response.data.id,
      status: response.data.full_supply_state,
    })
  },
  clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS')
  },
  clearFilter({ commit }, filterItem) {
    commit('CLEAR_FILTER', filterItem)
  },
  async deleteOrder({ commit }, params) {
    const response = await ordersApi.delete(params)
    commit('DELETE_ORDER', response.data)
  },
  filterOrders({ commit }) {
    commit('CLEAR_ORDERS')
  },
  async getOrders({ state, commit, dispatch, getters }, infiniteLoaderContext) {
    commit('SET_SHARED_INDEX_PARAMS')

    const { current_page } = state
    const startInfiniteId = state.infiniteId
    if (state.totals.length === 0) dispatch('getTotals')

    try {
      const response = await ordersApi.index({
        ...state.sharedParams.index,
        page: current_page + 1,
        sort: state.sort.value && state.sort.value.attr,
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_ORDERS', {
        data: response.data,
        noFilters: isEmpty(state.q) && isEmpty(getters.appliedFilters),
      })
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  async getTotals({ state, commit }) {
    commit('SET_SHARED_INDEX_PARAMS')
    const response = await ordersApi.totals.index({
      ...state.sharedParams.index,
      group: state.filters.group.value.name,
    })

    commit('ADD_TOTALS', response.data)
  },
  async getPdf({ state, commit }, { type }) {
    commit('SET_SHARED_INDEX_PARAMS')
    return ordersApi.pdf.index({
      ...state.sharedParams.index,
      pdf_type: type,
    })
  },
  async loadCostCentersFilterOptions({ commit }, { company_id }) {
    commit('SET_FILTER_LOADING', { value: true, type: 'cost_centers' })

    const response = await costCentersApi.index({ all: true, company_id })

    commit('SET_COST_CENTER_FILTER_OPTIONS', response.data.cost_centers)

    commit('SET_FILTER_LOADING', { value: false, type: 'cost_centers' })
  },
  async loadSuppliersFilterOptions({ commit }) {
    commit('SET_FILTER_LOADING', { value: true, type: 'suppliers' })

    const response = await supplierTenantsApi.index({ all: true })

    commit('SET_FILTER_OPTIONS', {
      data: response.data,
      type: 'suppliers',
    })

    commit('SET_FILTER_LOADING', { value: false, type: 'suppliers' })
  },
  async loadOrderVersions({ commit, state }, params) {
    const { current_page } = state.versions
    const { paginationContext, id } = params

    try {
      const response = await ordersApi.versions.index(id, {
        page: current_page + 1,
      })

      if (response.data.pages <= current_page) {
        paginationContext.complete()
      } else {
        paginationContext.loaded()
      }

      commit('ADD_ORDER_VERSIONS_DATA', response.data)
    } catch (_error) {
      paginationContext.error()
    }
  },
  resetVersions({ commit }) {
    commit('RESET_ORDER_VERSIONS')
  },
  setDeliveryDateRange({ commit }, range) {
    commit('SET_DELIVERY_DATE_RANGE', range)
  },
  setOrderDateRange({ commit }, range) {
    commit('SET_ORDER_DATE_RANGE', range)
  },
  setOrderStatus({ commit }, { id, status }) {
    commit('CHANGE_ORDER_STATE', {
      id: id,
      status: status,
    })
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  toggleOrderExpand({ commit }, orderId) {
    commit('TOGGLE_ORDER_EXPAND', orderId)
  },
  async updateOrder({ commit }, { order_id, ...params }) {
    const response = await ordersApi.update(order_id, params)
    commit('REPLACE_ORDER', response.data)
    return response.data
  },
}

export const getters = {
  areOrdersGrouped(state) {
    return state.filters.group.value.name !== 'ungrouped'
  },
  appliedFilters: (state) => {
    const { filters } = state

    return [
      ...filters.state.value,
      ...filters.suppliers.value,
      ...filters.cost_centers.value,
      {
        name:
          filters.delivery_date.start_at &&
          `${filters.delivery_date.start_at} - ${filters.delivery_date.end_at}`,
        type: 'delivery_date',
      },
      {
        name:
          filters.order_date.start_at &&
          `${filters.order_date.start_at} - ${filters.order_date.end_at}`,
        type: 'order_date',
      },
    ].filter((filterItem) => filterItem && filterItem.name)
  },
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
  isAnyStatusFilterPresent: (state) => {
    return state.filters.state.value.length > 0
  },
  isAnySuppliersFilterPresent: (state) => {
    return state.filters.suppliers.value.length > 0
  },
  isGrouped: (state) => {
    return (
      state.filters.group.value &&
      state.filters.group.value.name !== 'ungrouped'
    )
  },
  groupedOrders(state) {
    const groupFunc = (state, order) => {
      const date = dayjs(order.created_at, 'DD.MM.YYYY')

      if (state.filters.group.value.name === 'week') {
        return `${date.week()}: ${date.weekday(1).format('DD.MM')} - ${date
          .weekday(7)
          .format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'day') {
        return `${date.format('dddd')}:${date.format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'month') {
        return date.format('MM:YYYY')
      }

      if (state.filters.group.value.name === 'master_order') {
        return order.master_order_number
      }

      return 'all'
    }

    const totals = groupBy(state.totals, (total) => groupFunc(state, total))
    const orders = groupBy(state.list, (order) => groupFunc(state, order))

    return { orders, totals }
  },

  groupedOrdersByCostCenter: (state) => (cost_center) => {
    const groupFunc = (state, order) => {
      const date = dayjs(order.created_at, 'DD.MM.YYYY')

      if (state.filters.group.value.name === 'week') {
        return `${date.week()}: ${date.weekday(1).format('DD.MM')} - ${date
          .weekday(7)
          .format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'day') {
        return `${date.format('dddd')}:${date.format('DD.MM.YYYY')}`
      }

      if (state.filters.group.value.name === 'month') {
        return date.format('MM:YYYY')
      }

      if (state.filters.group.value.name === 'master_order') {
        return order.master_order_number
      }

      return 'all'
    }

    const totals = groupBy(
      state.totals.filter((total) => total.cost_center_name === cost_center),
      (total) => groupFunc(state, total)
    )
    const orders = groupBy(
      state.list.filter((order) => order.cost_center.name === cost_center),
      (order) => groupFunc(state, order)
    )

    return { orders, totals }
  },

  ordersCostCenters(state) {
    return [...new Set(state.list.map((order) => order.cost_center.name))]
  },
}
