<template>
  <div
    class="relative rounded-10 h-14 bg-s1-main-100"
    :class="{
      'ring-2 ring-s1-blue-100 focus:outline-none with-border': isOpened,
      'border-0 ring-s1-gray-50 ring-1': !isOpened,
      'hover:ring-s1-blue-100': !disabled,
      'opacity-50': disabled,
      'invalid-select-input': invalid,
    }"
  >
    <label
      v-if="showLabel"
      class="absolute top-0 z-30 block pl-1 pt-0 mt-1 ml-2 text-xxs pointer-events-none bg-s1-main-100 text-input-label"
      :class="{ 'z-30': !isOpened, 'z-60': isOpened }"
    >
      {{ label }}
    </label>
    <Multiselect
      deselectLabel=""
      open-direction="openDirection"
      selectLabel=""
      selectedLabel=""
      :class="{
        'multiselect__tags--invalid': invalid,
      }"
      :disabled="disabled"
      :loading="loading"
      :options="options"
      :placeholder="placeholder"
      :track-by="trackBy"
      :value="value"
      :custom-label="customLabel"
      :preselect-first="preselect"
      :clear-on-select="clearOnSelect"
      :preserve-search="preserveSearch"
      @input="changeValue"
      @open="openOptions"
      @search-change="searchChange"
      @select="selectValue"
      @remove="removeValue"
      @close="closeOptions"
      v-bind="$attrs"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template #caret>
        <div class="absolute right-0 flex items-center h-full">
          <ActionIcon
            name="actions_cart_dropdown"
            class="text-input-label text-2xl pr-3"
          />
        </div>
      </template>
      <template #noOptions>{{ $t('shared.list_is_empty') }}</template>
    </Multiselect>
  </div>
</template>

<style scoped>
.invalid-select-input {
  @apply ring-s1-red-100 !important;
}
</style>

<script>
import ActionIcon from '@/components/shared/atoms/action-icon'
import Multiselect from 'vue-multiselect'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    inputLabel: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    trackBy: {
      type: String,
      default: 'name',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: 'bottom',
    },
    subheader: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: Function,
      default: (option) => option.name,
    },
    preselect: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
    preserveSearch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ActionIcon,
    Multiselect,
  },
  data() {
    return {
      showLabel: true,
      isOpened: false,
    }
  },
  created() {
    this.updateLabel()
  },
  methods: {
    changeValue(value) {
      this.showLabel = !!value
      this.$emit('input', value)
    },
    selectValue(value) {
      this.showLabel = !!value
      this.$emit('select', value)
    },
    removeValue(value) {
      this.$emit('remove', value)
    },
    searchChange(searchQuery, id) {
      this.$emit('search-change', searchQuery, id)
    },
    closeOptions() {
      this.isOpened = false
      this.updateLabel()
      this.$emit('close')
    },
    openOptions() {
      this.showLabel = true
      this.isOpened = true
      this.$emit('open')
    },
    updateLabel() {
      if (Array.isArray(this.value)) {
        this.showLabel = this.value.length > 0
      } else {
        this.showLabel = !!this.value
      }
    },
  },
  watch: {
    value() {
      this.updateLabel()
    },
  },
  computed: {
    placeholder() {
      return this.isOpened ? this.$t('shared.search') : this.label
    },
  },
}
</script>
