import apiClient from '@/services/api'

export default {
  delete(id) {
    return apiClient.delete(`api/import_mappings/${id}`)
  },
  index(params) {
    return apiClient.get('api/import_mappings', { params })
  },
  show(id) {
    return apiClient.get(`api/import_mappings/${id}`)
  },
  update(id, data) {
    return apiClient.put(`api/import_mappings/${id}`, data)
  },
  importMappingColumns: {
    index(importMappingId) {
      return apiClient.get(
        `api/import_mappings/${importMappingId}/import_mapping_columns`
      )
    },
  },
}
