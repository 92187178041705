import companiesApi from '@/services/api/companies'
import costCentersApi from '@/services/api/cost-centers'
import invitations from '@/services/api/invitations'
import lockedUsers from '@/services/api/locked-users'
import usersApi from '@/services/api/users'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  global_count: null,
  current_page: 0,
  isInviteModalOpened: false,
  isManageUserModalOpened: false,
  isSubmitting: false,
  pages: 0,
  per_page: 0,
  q: null,
  filters: {},
  defaultFilters: {
    roles: {
      value: [],
      options: [
        {
          name: 'account_owner',
          type: 'roles',
        },
        {
          name: 'company_admin',
          type: 'roles',
        },
        {
          name: 'cost_center_admin',
          type: 'roles',
        },
        {
          name: 'supervisor',
          type: 'roles',
        },
        {
          name: 'accountant',
          type: 'roles',
        },
        {
          name: 'inspector',
          type: 'roles',
        },
      ],
      type: 'checkbox',
      selected: false,
    },
    statuses: {
      value: [],
      options: [
        { name: 'invited', status: 0, type: 'statuses', label: 'Invited' },
        { name: 'active', status: 1, type: 'statuses', label: 'Active' },
        { name: 'locked', status: 2, type: 'statuses', label: 'Locked' },
      ],
      selected: false,
    },
    cost_centers: {
      value: [],
      options: [],
      loading: false,
      withAsyncOptions: true,
      type: 'checkbox',
    },
    companies: {
      value: [],
      options: [],
      loading: false,
      withAsyncOptions: true,
      type: 'checkbox',
    },
  },
  internalFilters: {
    roles: {
      value: [],
      options: [
        { name: 'admin', type: 'roles' },
        { name: 'support', type: 'roles' },
      ],
      type: 'checkbox',
      selected: false,
    },
    statuses: {
      value: [],
      options: [
        { name: 'invited', status: 0, type: 'statuses', label: 'Invited' },
        { name: 'active', status: 1, type: 'statuses', label: 'Active' },
        { name: 'locked', status: 2, type: 'statuses', label: 'Locked' },
      ],
      selected: false,
    },
  },
  sort: {
    value: {
      name: 'user_profiles.first_name_asc',
      attr: 'user_profiles.first_name',
    },
    options: [
      {
        name: 'user_profiles.first_name_asc',
        attr: 'user_profiles.first_name',
      },
      {
        name: 'user_profiles.first_name_desc',
        attr: '-user_profiles.first_name',
      },
      {
        name: 'user_profiles.last_name_asc',
        attr: 'user_profiles.last_name',
      },
      {
        name: 'user_profiles.last_name_desc',
        attr: '-user_profiles.last_name',
      },
      {
        name: 'user_profiles.initials_asc',
        attr: 'user_profiles.initials',
      },
      {
        name: 'user_profiles.initials_desc',
        attr: '-user_profiles.initials',
      },
      { name: 'email_asc', attr: 'spree_users.email' },
      { name: 'email_desc', attr: '-spree_users.email' },
      { name: 'last_login_asc', attr: 'spree_users.last_sign_in_at' },
      { name: 'last_login_desc', attr: '-spree_users.last_sign_in_at' },
      { name: 'user_profiles.status_asc', attr: 'user_profiles.status' },
      {
        name: 'user_profiles.status_desc',
        attr: '-user_profiles.status',
      },
    ],
  },
  list: [],
  team_list: [],
  infiniteId: +new Date(),
  versions: {
    infiniteId: +new Date(),
    list: [],
    count: 0,
    total_count: 0,
    current_page: 0,
    pages: 0,
    per_page: 0,
  },
}

export const mutations = {
  ADD_USERS_DATA(state, { users, ...paginationMeta }) {
    state.list = [...state.list, ...users]
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.global_count = paginationMeta.global_count
  },
  ADD_USER_DATA(state, user) {
    state.list.push(user)
    state.total_count += 1
  },
  ADD_USER_VERSIONS_DATA(state, { versions, ...paginationMeta }) {
    state.versions.list = [...state.versions.list, ...versions]
    state.versions.current_page += 1
    state.versions.total_count = paginationMeta.total_count
  },
  ADD_TEAM_LIST_DATA(state, { users }) {
    state.team_list = [...users]
  },
  CLEAR_ALL_FILTERS(state) {
    const { filters } = state

    Object.keys(filters).forEach((filterName) => {
      filters[filterName].value = []
    })
  },
  CLEAR_FILTER(state, filterItem) {
    state.filters[filterItem.type].value = state.filters[
      filterItem.type
    ].value.filter((item) => {
      return item.name !== filterItem.name
    })
  },
  CLOSE_ALL_USER_MODALS(state) {
    state.isInviteModalOpened = false
    state.isManageUserModalOpened = false
  },
  SET_FILTER_LOADING(state, { type, value }) {
    state.filters[type].loading = value
  },
  SET_FILTER_OPTIONS(state, { type, data }) {
    state.filters[type].options = data[type].map((item) => {
      return {
        id: item.id,
        name: item.name,
        type,
      }
    })
  },
  SWITCH_INVITATION_MODAL(state, value) {
    state.isInviteModalOpened = value
  },
  SWITCH_MANAGE_USER_MODAL(state, value) {
    state.isManageUserModalOpened = value
  },
  REPLACE_USER_DATA(state, user) {
    state.list = state.list.map((storedUser) =>
      storedUser.id === user.id ? user : storedUser
    )
  },
  REPLACE_USER_ROLE_RELATIONS(state, user) {
    state.list.forEach((storedUser) => {
      if (storedUser.id === user.id) {
        storedUser.role_relations = user.role_relations
        storedUser.roles = user.roles
      }
    })
  },
  REMOVE_USER_DATA(state, id) {
    state.list = state.list.filter((user) => user.id !== id)
    state.total_count -= 1
  },
  RESET_LOADED_USERS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  RESET_USER_VERSIONS(state) {
    state.versions.list = []
    state.versions.current_page = 0
    state.versions.total_count = 0
  },
  SET_DEFAULT_FILTERS(state) {
    state.filters = { ...state.defaultFilters }
  },
  SET_INTERNAL_FILTERS(state) {
    state.filters = { ...state.internalFilters }
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
}

export const actions = {
  async clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS')
  },
  async clearFilter({ commit }, filterItem) {
    commit('CLEAR_FILTER', filterItem)
  },
  closeAllUserModals({ commit }) {
    commit('CLOSE_ALL_USER_MODALS')
  },
  async loadCompaniesFilterOptions({ commit }) {
    commit('SET_FILTER_LOADING', { type: 'companies', value: true })

    const response = await companiesApi.index({ all: true })

    commit('SET_FILTER_LOADING', { type: 'companies', value: false })
    commit('SET_FILTER_OPTIONS', {
      data: response.data,
      type: 'companies',
    })
  },
  async loadCostCentersFilterOptions({ commit }) {
    commit('SET_FILTER_LOADING', { type: 'cost_centers', value: true })

    const response = await costCentersApi.index({ all: true })

    commit('SET_FILTER_LOADING', { type: 'cost_centers', value: false })
    commit('SET_FILTER_OPTIONS', {
      data: response.data,
      type: 'cost_centers',
    })
  },
  switchInvitationModal({ commit }, value) {
    commit('SWITCH_INVITATION_MODAL', value)
  },
  switchManageUserModal({ commit }, value) {
    commit('SWITCH_MANAGE_USER_MODAL', value)
  },
  async deleteRoleRelation({ commit }, { userId, roleRelationId }) {
    const response = await usersApi.role_relations.delete(
      userId,
      roleRelationId
    )

    commit('REPLACE_USER_ROLE_RELATIONS', response.data)
  },
  async deleteUser({ commit }, id) {
    await usersApi.delete(id)

    commit('REMOVE_USER_DATA', id)
  },
  clearUsers({ commit }) {
    commit('RESET_LOADED_USERS')
  },
  filterUsers({ state, commit }, filterItem) {
    if (filterItem) {
      if (
        state.filters[filterItem.type].value.find(
          (item) => item.name === filterItem.name
        )
      ) {
        state.filters[filterItem.type].value = state.filters[
          filterItem.type
        ].value.filter((existingItem) => {
          return existingItem.name !== filterItem.name
        })
      } else {
        state.filters[filterItem.type].value.push(filterItem)
      }
    }
    commit('RESET_LOADED_USERS')
  },
  async getPasswordRecoveryPage(_context, token) {
    await usersApi.password.show(token)
  },
  async inviteUser({ commit }, params) {
    const response = await invitations.create(params)

    commit('ADD_USER_DATA', response.data)
  },
  async loadUsers({ commit, state }, { infiniteLoaderContext, internal }) {
    const {
      current_page,
      q,
      filters: { roles, statuses, cost_centers, companies },
      sort: { value },
    } = state

    try {
      const response = await usersApi.index({
        page: current_page + 1,
        q,
        sort: value && value.attr,
        status: statuses.value.map((item) => item.status).join(','),
        role: roles.value.map((item) => item.name).join(','),
        cost_centers: cost_centers
          ? cost_centers.value.map((item) => item.name).join(';')
          : [],
        companies: companies
          ? companies.value.map((item) => item.name).join(';')
          : [],
        internal: internal,
      })

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_USERS_DATA', response.data)
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  async loadTeamList({ commit }) {
    const response = await usersApi.index({
      all: true,
      internal: false,
    })
    commit('ADD_TEAM_LIST_DATA', response.data)
  },
  async loadUserVersions({ commit, state }, params) {
    const { current_page } = state.versions
    const { paginationContext, id } = params

    try {
      const response = await usersApi.versions.index(id, {
        page: current_page + 1,
      })

      if (response.data.pages <= current_page) {
        paginationContext.complete()
      } else {
        paginationContext.loaded()
      }

      commit('ADD_USER_VERSIONS_DATA', response.data)
    } catch (error) {
      paginationContext.error()
    }
  },
  async lockUser({ commit }, id) {
    const response = await lockedUsers.update(id)

    commit('REPLACE_USER_DATA', response.data)
  },
  async reinviteUser(_context, id) {
    await usersApi.invitations.update(id)
  },
  async resetVersions({ commit }) {
    commit('RESET_USER_VERSIONS')
  },
  async restorePassword(_context, params) {
    return await usersApi.password.create(params)
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  async unlockUser({ commit }, id) {
    const response = await lockedUsers.delete(id)

    commit('REPLACE_USER_DATA', response.data)
  },
  async createRoleRelation({ commit }, { id, ...params }) {
    const response = await usersApi.role_relations.create(id, params)

    commit('REPLACE_USER_ROLE_RELATIONS', response.data)
  },
  async updateRoleRelation(
    { state, commit },
    { userId, roleRelationId, ...params }
  ) {
    state.isSubmitting = true

    try {
      const response = await usersApi.role_relations.update(
        userId,
        roleRelationId,
        params
      )
      commit('REPLACE_USER_ROLE_RELATIONS', response.data)
    } finally {
      state.isSubmitting = false
    }
  },
  async updateUser({ commit }, { id, ...params }) {
    const response = await usersApi.update(id, params)

    commit('REPLACE_USER_DATA', response.data)
  },
  async updateUserPassword({ commit }, { token, ...params }) {
    const response = await usersApi.password.update(token, params)

    commit('currentUser/SET_CURRENT_USER_TOKENS', response.data, { root: true })
    commit('currentUser/SET_CURRENT_USER_DATA', response.data, { root: true })

    return response.data.user
  },
  setDefaultFilters({ commit }) {
    commit('SET_DEFAULT_FILTERS')
  },
  setInternalFilters({ commit }) {
    commit('SET_INTERNAL_FILTERS')
  },
}

export const getters = {
  appliedFilters: (state) => {
    const { filters } = state
    return Object.values(filters)
      .map((filter) => [...filter.value])
      .flat()
  },
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
  invitedUsers: (state) => {
    return state.list.filter((user) => user.status === 'invited')
  },
  registeredUsers: (state) => {
    return state.list.filter((user) => user.status !== 'invited')
  },
}
