import en from '@/i18n/en.json'
import de from '@/i18n/de.json'

export const languages = {
  en: en,
  de: de,
}

export const defaultLocale = function () {
  const clientLocale = navigator.language || navigator.userLanguage
  const userString = localStorage.getItem('currentUser')

  if (userString) {
    const user = JSON.parse(userString)

    if (user && user.preference && user.preference.locale)
      return user.preference.locale
  }

  if (clientLocale) {
    const alpha2Code = clientLocale.split('-')[0]

    if (languages[alpha2Code]) return alpha2Code
  }

  return 'de'
}
