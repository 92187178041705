import axios from 'axios'
import store from '@/store'
import TokenStorage from '@/services/token-storage'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const accessToken = TokenStorage.getAccessToken()
    const refreshToken = TokenStorage.getRefreshToken()

    if (!accessToken) {
      return config
    }

    const newConfig = {
      headers: {},
      ...config,
    }

    newConfig.headers.Authorization = `Bearer ${accessToken}`
    newConfig.headers['X-Refresh-Token'] = `Bearer ${refreshToken}`

    return newConfig
  },
  (error) => {
    throw error
  }
)

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { response, config } = error

    if (error.message === 'Network Error') {
      throw error
    }

    if (error.response && response.status === 403) {
      window.location = '/dashboard'
    }

    if (
      (error.response &&
        response.status === 401 &&
        config.url === 'api/session' &&
        config.method === 'post') ||
      (error.response && response.status !== 401)
    ) {
      throw error
    }

    if (
      !TokenStorage.getRefreshToken() ||
      config.retry ||
      (error.response &&
        response.status === 401 &&
        config.url === 'api/session' &&
        config.method === 'put')
    ) {
      await store.commit('currentUser/REMOVE_CURRENT_USER_TOKENS')
      throw error
    }

    const { tokens } = await store.dispatch('currentUser/updateSession')

    TokenStorage.setAccessToken(tokens.access)

    const newRequest = {
      ...config,
      retry: true,
      headers: { Authorization: `Berear ${tokens.access}` },
    }

    return apiClient(newRequest)
  }
)

export default apiClient
