import {
  always,
  append,
  compose,
  find,
  findIndex,
  flatten,
  ifElse,
  includes,
  map,
  path,
  pick,
  prop,
  propEq,
  reject,
  sortBy,
  unless,
  when,
  without,
} from 'ramda'
import { uniqBy, isEmpty } from 'lodash'
import companiesApi from '@/services/api/companies'
import costCentersApi from '@/services/api/cost-centers'
import lockedUsers from '@/services/api/locked-users'

export const namespaced = true

export const state = {
  all: [],
  list: [],
  count: 0,
  current_page: 0,
  expanded: [],
  filters: {
    names: {
      withAsyncOptions: true,
      loading: false,
      value: [],
      options: [],
    },
  },
  sort: {
    value: { name: 'name_asc', attr: 'name' },
    options: [
      { name: 'name_asc', attr: 'name' },
      { name: 'name_desc', attr: '-name' },
    ],
  },
  infiniteId: +new Date(),
  pages: 0,
  per_page: 0,
  q: null,
  total_count: 0,
  currentCompany: null,
  currentBusinessUnitUsers: null,
  currentCostCenter: null,
  isSubmitting: false,
  isBlankState: null,
}

export const getters = {
  appliedFilters: (state) => {
    const { filters } = state

    return [...filters.names.value]
  },
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
  isEmpty: (state) => {
    return state.list.length === 0 && !state.q
  },
  getCompanyById: (state) => (companyId) =>
    state.list.filter((company) => company.id === companyId)[0],

  getCostCenterById: (state) => (costCenterId) =>
    compose(
      find(propEq('id', costCenterId)),
      flatten,
      map(prop('cost_centers'))
    )(state.list),

  getCurrentCompanyUsers: (state) => {
    return state.currentCompany.users
  },
  showAllCompanies: (_state, _getters, rootState) => {
    return rootState.currentUser.currentUser.show_all_companies
  },
}

export const mutations = {
  ADD_MEMBERS_TO_BUSINESS_UNIT(state, { id, type, invitees }) {
    if (type === 'Company') {
      const company = state.list.find((company) => company.id === id)

      if (company) {
        company.invitees = [...company.invitees, ...invitees]
      }
    } else {
      state.list.forEach((company) => {
        const costCenter = company.cost_centers.find(
          (costCenter) => costCenter.id === id
        )

        if (costCenter) {
          costCenter.invitees = [...costCenter.invitees, ...invitees]
        }
      })
    }
  },
  TOGGLE_COMPANY_EXPAND(state, companyId) {
    state.expanded = ifElse(
      includes(companyId),
      without([companyId]),
      append(companyId)
    )(state.expanded)
  },
  EXPAND_COMPANY(state, companyId) {
    state.expanded = unless(
      includes(companyId),
      append(companyId)
    )(state.expanded)
  },
  CLEAR_ALL_FILTERS(state) {
    const { filters } = state

    filters.names.value = []
  },
  CLEAR_COMPANIES(state) {
    state.list = []
    state.all = []
    state.current_page = 0
    state.infiniteId += 1
  },
  CLEAR_FILTER(state, filterItem) {
    state.filters.names.value = state.filters.names.value.filter((item) => {
      return item.name !== filterItem.name
    })
  },
  ADD_COMPANIES(state, { data: { companies, ...paginationMeta }, noFilters }) {
    companies.forEach((company) => {
      company.invitees = []
      company.cost_centers.forEach((costCenter) => {
        costCenter.invitees = []
      })
    })

    state.list = uniqBy([...state.list, ...companies], 'id')
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.isBlankState = noFilters && paginationMeta.total_count === 0
  },
  SET_ALL_COMPANIES(state, { companies }) {
    state.filters.names.options = map(pick(['id', 'name']), companies)
  },
  ADD_COMPANY(state, company) {
    company.invitees = []
    state.list = compose(sortBy(prop('name')), append(company))(state.list)
    state.total_count++
    state.isBlankState = false
  },
  SET_COMPANY(state, company) {
    state.list = map(
      when(propEq('id', company.id), always(company)),
      state.list
    )
    state.currentCompany = company
  },
  UPDATE_COMPANIES_LIST(state, company) {
    state.list = map(
      when(propEq('id', company.id), always(company)),
      state.list
    )
  },
  UPDATE_COST_CENTER_LIST(state, costCenter) {
    map(
      when(propEq('id', costCenter.company_id), (company) => {
        const index = findIndex(
          propEq('id', costCenter.id),
          company.cost_centers
        )

        company.cost_centers.splice(index, 1, costCenter)

        return company
      })
    )(state.list)
  },
  SET_CURRENT_COMPANY_USERS(state, users) {
    state.currentCompany.users = users
  },
  UPDATE_CURRENT_COMPANY_USERS(state, user) {
    state.currentCompany.users = state.currentCompany.users.filter((usr) => {
      return usr.id !== user.id
    })
    state.currentCompany.users.push(user)
  },
  SET_FILTER_NAMES_LOADING(state, value) {
    state.filters.names.loading = value
  },
  EDIT_COMPANY(state, company) {
    state.list = map(
      when(propEq('id', company.id), always(company)),
      state.list
    )
  },
  DELETE_COMPANY(state, company) {
    state.list = reject(propEq('id', company.id))(state.list)
    state.total_count -= 1
  },
  ADD_MEMBER_TO_COST_CENTER(_state, { costCenterId, user }) {
    state.list.forEach((company) =>
      company.cost_centers.forEach((costCenter) => {
        if (costCenter.id === costCenterId) {
          costCenter.users.push(user)
        }
      })
    )
  },
  ADD_COST_CENTER(state, costCenter) {
    const company = state.list.find(
      (company) => company.id === costCenter.company_id
    )

    company.cost_centers = [...company.cost_centers, costCenter]
  },
  SET_COST_CENTER(state, costCenter) {
    map(
      when(propEq('id', costCenter.company_id), (company) => {
        const index = findIndex(
          propEq('id', costCenter.id),
          company.cost_centers
        )

        company.cost_centers.splice(index, 1, costCenter)

        return company
      })
    )(state.list)
    state.currentCostCenter = costCenter
  },
  EDIT_COST_CENTER(state, costCenter) {
    map(
      when(propEq('id', costCenter.company_id), (company) => {
        const index = findIndex(
          propEq('id', costCenter.id),
          company.cost_centers
        )

        company.cost_centers.splice(index, 1, costCenter)

        return company
      })
    )(state.list)
  },
  DELETE_COST_CENTER(state, costCenter) {
    map(
      when(propEq('id', costCenter.company_id), (company) => {
        const index = findIndex(
          propEq('id', costCenter.id),
          company.cost_centers
        )

        company.cost_centers.splice(index, 1)

        return company
      })
    )(state.list)
  },
  MOVE_INVITEES_TO_COMPANY_MEMBERS(state, { id, users }) {
    const company = state.list.find((company) => company.id === id)

    if (company) {
      company.invitees = []
      company.users = [...company.users, ...users]
    }
  },
  MOVE_INVITEES_TO_COST_CENTER_MEMBERS(state, { id, users }) {
    state.list.forEach((company) => {
      const costCenter = company.cost_centers.find(
        (costCenter) => costCenter.id === id
      )

      if (costCenter) {
        costCenter.invitees = []
        costCenter.users = [...costCenter.users, ...users]
      }
    })
  },
  REPLACE_COMPANY_MEMBER(state, { user: newUser, company_id }) {
    const company = state.list.find((company) => company.id === company_id)

    company.users = company.users.map((user) =>
      user.id === newUser.id ? newUser : user
    )
  },
  REPLACE_COST_CENTER(state, data) {
    const company = state.list.find((company) => company.id === data.company_id)

    if (company) {
      company.cost_centers = company.cost_centers.map((costCenter) =>
        costCenter.id === data.id ? data : costCenter
      )
    }
  },
  REPLACE_COST_CENTER_USER(state, user) {
    state.list.forEach((company) => {
      company.cost_centers.forEach((costCenter) => {
        costCenter.users = costCenter.users.map((costCenterUser) => {
          if (costCenterUser.id === user.id) {
            return user
          } else {
            return costCenterUser
          }
        })
      })
    })
  },
  REMOVE_COST_CENTER_USER(state, { costCenterId, userId }) {
    state.list.forEach((company) => {
      const costCenter = company.cost_centers.find(
        (costCenter) => costCenter.id === costCenterId
      )

      if (costCenter) {
        costCenter.users = costCenter.users.filter((user) => user.id !== userId)
      }
    })
  },
  REMOVE_COST_CENTER_INVITEE(state, { costCenterId, email }) {
    state.list.forEach((company) => {
      const costCenter = company.cost_centers.find(
        (costCenter) => costCenter.id === costCenterId
      )

      if (costCenter) {
        costCenter.invitees = costCenter.invitees.filter(
          (invitee) => invitee.email !== email
        )
      }
    })
  },
  REMOVE_MEMBER_FROM_COMPANY(state, { companyId, userId }) {
    const company = state.list.find((company) => company.id === companyId)

    company.users = company.users.filter((user) => user.id !== userId)
  },
  REMOVE_MEMBER_FROM_COST_CENTER(state, { costCenterId, userId }) {
    state.list.forEach((company) => {
      const costCenter = company.cost_centers.find(
        (costCenter) => costCenter.id === costCenterId
      )

      if (costCenter) {
        costCenter.users = costCenter.users.filter((user) => user.id !== userId)
      }
    })
  },
  REPLACE_USER_DATA(state, user) {
    state.currentCompany.users.forEach((usr) => {
      if (usr.id === user.id) {
        usr = user
      }
    })
    state.currentCompany.cost_centers.forEach((cost_center) => {
      cost_center.users.forEach((usr) => {
        if (usr.id === user.id) {
          usr = user
        }
      })
    })
  },
  REPLACE_CURRENT_COMPANY_DATA(state, { data }) {
    state.currentCompany = data
    state.currentCostCenter = data.cost_centers.find(
      (item) => item.id === state.currentCostCenter?.id
    )
  },
  REPLACE_USER_ROLE_RELATIONS(state, user) {
    state.currentCompany.users.forEach((usr) => {
      if (usr.id === user.id) {
        usr.role_relations = user.role_relations
      }
    })
    state.currentCompany.cost_centers.forEach((cost_center) => {
      cost_center.users.forEach((usr) => {
        if (usr.id === user.id) {
          usr.role_relations = user.role_relations
        }
      })
    })
  },
  SET_COST_CENTER_INVITEES(state, { costCenterId, invitees }) {
    state.list.forEach((company) => {
      const costCenter = company.cost_centers.find(
        (costCenter) => costCenter.id === costCenterId
      )

      if (costCenter) {
        costCenter.invitees = invitees
      }
    })
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected

    state.filters[filterItem.name].loading = true
  },
}

export const actions = {
  addMembersToBusinessUnit({ commit }, { id, type, invitees }) {
    commit('ADD_MEMBERS_TO_BUSINESS_UNIT', { id, type, invitees })
  },
  async unlockUser({ commit }, { id, company_id, ...params }) {
    const response = await lockedUsers.companies.delete(id, company_id, params)

    commit('REPLACE_CURRENT_COMPANY_DATA', response)
  },
  async lockUser({ commit }, { id, company_id, ...params }) {
    const response = await lockedUsers.companies.update(id, company_id, params)

    commit('REPLACE_CURRENT_COMPANY_DATA', response)
  },
  async createRoleRelation({ commit }, { id, company_id, ...params }) {
    const response = await companiesApi.role_relations.create(
      id,
      company_id,
      params
    )

    commit('REPLACE_USER_ROLE_RELATIONS', response.data)
    commit('REPLACE_USER_DATA', response.data)
  },
  async deleteRoleRelation({ commit }, { userId, roleRelationId, company_id }) {
    const response = await companiesApi.role_relations.delete(
      userId,
      roleRelationId,
      company_id
    )

    commit('REPLACE_USER_ROLE_RELATIONS', response.data)
    commit('REPLACE_USER_DATA', response.data)
  },
  async updateRoleRelation(
    { state, commit },
    { userId, roleRelationId, company_id, ...params }
  ) {
    state.isSubmitting = true
    const response = await companiesApi.role_relations.update(
      userId,
      roleRelationId,
      company_id,
      params
    )

    commit('REPLACE_USER_ROLE_RELATIONS', response.data)
    state.isSubmitting = false
  },
  updateCurrentBusinessUnitUsers({ commit }, user) {
    commit('UPDATE_CURRENT_COMPANY_USERS', user)
  },
  async clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS')
  },
  async clearFilter({ commit }, filterItem) {
    commit('CLEAR_FILTER', filterItem)
  },
  clearCompanies({ commit }) {
    commit('CLEAR_COMPANIES')
  },
  setCurrentBusinessUnitUsers({ commit }, users) {
    commit('SET_CURRENT_COMPANY_USERS', users)
  },
  setCostCenterInvitees({ commit }, { costCenterId, invitees }) {
    commit('SET_COST_CENTER_INVITEES', { costCenterId, invitees })
  },
  toggleCompanyExpand({ commit }, companyId) {
    commit('TOGGLE_COMPANY_EXPAND', companyId)
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  async uploadCompanyLogo({ commit }, data) {
    const response = await companiesApi.attachments.create(data)

    commit('SET_COMPANY', response.data)
  },
  async updateCompanyStatus({ commit }, id) {
    const response = await companiesApi.delete(id)

    commit('UPDATE_COMPANIES_LIST', response.data.company)
    commit('currentUser/UPDATE_USER_PREFERENCE', response.data, {
      root: true,
    })
  },
  async updateCostCenterStatus({ commit }, id) {
    const response = await costCentersApi.delete(id)

    commit('UPDATE_COST_CENTER_LIST', response.data.cost_center)
    commit('currentUser/UPDATE_USER_PREFERENCE', response.data, {
      root: true,
    })
  },
  async deleteCompanyLogo({ commit }, id) {
    const response = await companiesApi.attachments.delete(id)

    commit('SET_COMPANY', response.data)
  },
  async uploadCostCenterLogo({ commit }, data) {
    const response = await costCentersApi.attachments.create(data)
    commit('SET_COST_CENTER', response.data)
  },
  async deleteCostCenterLogo({ commit }, id) {
    const response = await costCentersApi.attachments.delete(id)
    commit('SET_COST_CENTER', response.data)
  },
  expandCompany({ commit }, companyId) {
    commit('EXPAND_COMPANY', companyId)
  },
  async filterCompanies({ state, commit }, filterItem) {
    if (filterItem) {
      if (
        state.filters.names.value.find((item) => item.name === filterItem.name)
      ) {
        state.filters.names.value = state.filters.names.value.filter(
          (existingItem) => {
            return existingItem.name !== filterItem.name
          }
        )
      } else {
        state.filters.names.value.push(filterItem)
      }
    }
    commit('CLEAR_COMPANIES')
  },
  async getCompanies({ state, commit, getters }, infiniteLoaderContext) {
    const {
      current_page,
      q,
      sort: { value },
    } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await companiesApi.index({
        page: current_page + 1,
        q,
        sort: value && value.attr,
        company_ids: state.filters.names.value.map((item) => item.id).join(','),
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_COMPANIES', {
        data: response.data,
        noFilters: isEmpty(q) && getters.showAllCompanies === true,
      })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async getAllCompanies({ commit }) {
    const response = await companiesApi.index({ all: true })

    commit('SET_FILTER_NAMES_LOADING', false)
    commit('SET_ALL_COMPANIES', response.data)
  },
  async createCompany({ commit }, params) {
    const response = await companiesApi.create(params)
    commit('ADD_COMPANY', response.data)
    commit('TOGGLE_COMPANY_EXPAND', response.data.id)

    const currentData = JSON.parse(localStorage.getItem('currentUser'))
    const user = {
      user: { ...currentData, preference: response.data.user_preference },
    }

    commit('currentUser/UPDATE_USER_PREFERENCE', user, {
      root: true,
    })
    return response
  },
  async updateCompany({ commit }, params) {
    const response = await companiesApi.update(params)
    commit('EDIT_COMPANY', response.data)
    commit('EXPAND_COMPANY', response.data.id)

    return response.data
  },
  async updateCompanyMember({ commit }, { id, company_id, ...params }) {
    const response = await companiesApi.users.update(company_id, id, params)

    commit('REPLACE_COMPANY_MEMBER', { user: response.data, company_id })
  },
  async updateCostCenter({ commit }, data) {
    const response = await costCentersApi.update(data)

    commit('REPLACE_COST_CENTER', response.data)
  },
  async deleteCompany({ state, commit, getters }, id) {
    const response = await companiesApi.delete(id)

    commit('DELETE_COMPANY', {
      data: response.data.company,
      noFilters: isEmpty(state.q) && getters.showAllCompanies === true,
    })

    commit('currentUser/UPDATE_USER_PREFERENCE', response.data, {
      root: true,
    })

    return response.data.company
  },
  async deleteCompanyMember({ commit }, { companyId, userId }) {
    await companiesApi.users.delete(companyId, userId)

    commit('REMOVE_MEMBER_FROM_COMPANY', { companyId, userId })
  },
  async deleteCostCenterMember({ commit }, { costCenterId, userId }) {
    const response = await costCentersApi.users.delete(costCenterId, userId)

    commit('REPLACE_USER_ROLE_RELATIONS', response.data)
    commit('REPLACE_USER_DATA', response.data)

    commit('REMOVE_MEMBER_FROM_COST_CENTER', { costCenterId, userId })
  },
  async createCostCenter({ commit }, params) {
    const response = await costCentersApi.create(params)

    commit('ADD_COST_CENTER', response.data)

    const currentData = JSON.parse(localStorage.getItem('currentUser'))
    const user = {
      user: { ...currentData, preference: response.data.user_preference },
    }

    commit('currentUser/UPDATE_USER_PREFERENCE', user, {
      root: true,
    })
  },
  editCostCenter({ commit }, { id, ...params }) {
    return costCentersApi
      .update(id, {
        ...params,
        country: path(['country', 'iso'], params),
      })
      .then((response) => {
        commit('EDIT_COST_CENTER', response.data)
      })
  },
  async deleteCostCenter({ commit }, id) {
    const response = await costCentersApi.delete(id)

    commit('DELETE_COST_CENTER', response.data.cost_center)
    commit('currentUser/UPDATE_USER_PREFERENCE', response.data, {
      root: true,
    })

    return response.data.cost_center
  },
  async updateCostCenterMember({ commit }, { id, cost_center_id, ...params }) {
    const response = await costCentersApi.users.update(
      cost_center_id,
      id,
      params
    )

    commit('REPLACE_COST_CENTER_USER', response.data)
  },
  async deleteMember({ commit }, { costCenterId, userId }) {
    await costCentersApi.users.delete(costCenterId, userId)

    commit('REMOVE_COST_CENTER_USER', { costCenterId, userId })
  },
  removeCostCenterInvitee({ commit }, { costCenterId, email }) {
    commit('REMOVE_COST_CENTER_INVITEE', { costCenterId, email })
  },
  async inviteMembersToCostCenter({ commit }, { id, ...params }) {
    const response = await costCentersApi.user_list.create(id, params)

    commit('MOVE_INVITEES_TO_COST_CENTER_MEMBERS', {
      id,
      users: response.data.users,
    })

    return response
  },

  async inviteMembersToCompany({ commit }, { id, ...params }) {
    const response = await companiesApi.user_list.create(id, params)

    commit('MOVE_INVITEES_TO_COMPANY_MEMBERS', {
      id,
      users: response.data.users,
    })

    return response
  },

  async createSellyIntegrationCompany(_ctx, { companyId, ...params }) {
    const response = await companiesApi.integration_companies.create(
      companyId,
      params
    )
    return response.data
  },
}
