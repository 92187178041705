import apiClient from '@/services/api'

export default {
  create(params) {
    return apiClient.post('api/accounts', params)
  },
  index(params) {
    return apiClient.get('api/accounts', { params })
  },
  update(params) {
    return apiClient.put(`api/accounts/${params.id}`, params)
  },
  shorts: {
    index(params) {
      return apiClient.get(`api/accounts/shorts`, { params })
    },
  },
}
