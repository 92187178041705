<template>
  <div
    class="relative"
    :class="{
      'w-26 h-26': !overlayed && size === 'lg',
      'w-10 h-10': !overlayed && size === 'md',
      'w-8 h-8': !overlayed && size === 'sm',
      'w-7 h-7': !overlayed && size === 'xxs',
      [overlayedClasses]: overlayed,
    }"
  >
    <img
      v-if="user.avatar_url"
      class="object-cover rounded-full"
      :class="{
        'w-7 h-7': size === 'xxs',
        'w-8 h-8': size === 'sm',
        'w-10 h-10': size === 'md',
        'w-26 h-26': size === 'lg',
        'md:w-8 md:h-8 md:text-sm': overlayed,
      }"
      alt=""
      :src="user.avatar_url"
      v-tooltip="{
        content: `${user.first_name} ${user.last_name}`,
        placement: tooltipPlacement,
      }"
    />

    <div
      v-else-if="withMailIcon"
      class="flex items-center justify-center w-8 h-8 rounded-full bg-silver md:w-8 md:h-8"
    >
      <MailCircleIcon
        v-tooltip="user.email"
        class="w-4 h-4 md:w-auto md:h-auto"
      />
    </div>

    <InitialsCircle
      v-else
      class="inline-block object-cover text-sm"
      :class="{
        'w-7 h-7 text-xxs': size === 'xxs',
        'w-8 h-8 text-sm': size === 'sm',
        'w-10 h-10': size === 'md',
        'w-26 h-26 text-xl': size === 'lg',
        'md:w-8 md:h-8 md:text-sm': overlayed,
      }"
      v-tooltip="{
        content: `${user.first_name} ${user.last_name}`,
        placement: tooltipPlacement,
      }"
      :color-code="user.avatar_background"
    >
      <span :class="`text-${initialsTextColor}`">{{ user.initials }}</span>
    </InitialsCircle>

    <div
      v-if="withStatus"
      class="absolute w-3.5 h-3.5 text-center rounded-full flex items-center justify-center"
      :class="{
        [statusClass]: statusClass,
        'bg-s1-green-100': user.status === 'active',
        'bg-fs-mid-blue': user.status === 'invited',
        'bg-fs-red': user.status === 'locked',
        'bg-fs-light-coldgrey': user.status === 'offline',
        'top-2 right-2 w-4 h-4': size === 'lg',
        'top-0 -right-0.5': size === 'md' || size === 'sm',
        '-top-0.5 -right-0.5 left-5 bottom-5': size === 'xxs',
      }"
    >
      <div class="flex items-center justify-center w-full h-full">
        <span
          v-if="user.status === 'invited'"
          class="flex justify-self-center self-center text-white text-sm"
          ><SideNavIcon name="mail" color="alabaster" size="xxs"
        /></span>
        <ActionIcon
          v-if="user.status === 'locked'"
          name="actions_lock_outline"
          size="xxs"
          color="alabaster"
          class="flex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionIcon from '@/components/shared/atoms/action-icon'
import InitialsCircle from '@/components/shared/atoms/initials-circle'
import MailCircleIcon from '@/components/shared/atoms/mail-circle'
import SideNavIcon from '@/components/shared/atoms/side-nav-icon'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['xxs', 'sm', 'md', 'lg'].includes(value)
      },
    },
    overlayed: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    statusClass: {
      type: String,
      default: null,
    },
    withStatus: {
      type: Boolean,
      default: false,
    },
    withMailIcon: {
      type: Boolean,
      default: false,
    },
    initialsTextColor: {
      type: String,
      default: 'alabaster',
    },
    tooltipPlacement: {
      type: String,
      default: 'auto',
    },
  },
  components: {
    ActionIcon,
    InitialsCircle,
    MailCircleIcon,
    SideNavIcon,
  },
  computed: {
    overlayedClasses() {
      return `-ml-2 z-${
        this.count - this.index + 1
      } md:w-8 md:h-8 md:-ml-2 md:text-sm`
    },
  },
}
</script>
