import itemsApi from '@/services/api/items'

export const namespaced = true

export const state = {
  resource: null,
  loading: false,
  offers: [],
  versions: {
    count: 0,
    total_count: 0,
    current_page: 0,
    pages: 0,
    per_page: 0,
    infiniteId: +new Date(),
    list: [],
  },
}

export const mutations = {
  SET_ITEM(state, item) {
    state.resource = item
  },
  ASSIGN_OFFERS(state, data) {
    state.offers = data.prices
  },
  ADD_ITEM_VERSIONS_DATA(state, { versions, ...paginationMeta }) {
    state.versions.list = [...state.versions.list, ...versions]
    state.versions.current_page += 1
    state.versions.total_count = paginationMeta.total_count
  },
  RESET_ITEM_VERSIONS(state) {
    state.versions.list = []
    state.versions.current_page = 0
    state.versions.total_count = 0
  },
  SET_ITEM_LOADING(state, value) {
    state.loading = value
  },
}

export const actions = {
  async getItem({ commit }, id) {
    commit('SET_ITEM_LOADING', true)
    const response = await itemsApi.show(id)

    commit('SET_ITEM', response.data)
    commit('currentUser/SET_CURRENT_USER_CART', response.data.cart, {
      root: true,
    })
    commit('SET_ITEM_LOADING', false)

    return response
  },
  async getItemOffers({ commit }, id) {
    const response = await itemsApi.offers.index(id)
    commit('ASSIGN_OFFERS', response.data)

    return response
  },
  async loadItemVersions({ commit, state }, params) {
    const { current_page } = state.versions
    const { paginationContext, id } = params

    try {
      const response = await itemsApi.versions.index(id, {
        page: current_page + 1,
      })

      if (response.data.pages <= current_page) {
        paginationContext.complete()
      } else {
        paginationContext.loaded()
      }

      commit('ADD_ITEM_VERSIONS_DATA', response.data)
    } catch (_error) {
      paginationContext.error()
    }
  },
  async updateFlexPrices({ commit }, { id, ...params }) {
    const response = await itemsApi.update(id, params)
    commit('SET_ITEM', response.data)

    return response
  },
  async reloadItem({ state, commit }) {
    const response = await itemsApi.show(state.resource.id)

    commit('SET_ITEM', response.data)
    commit('currentUser/SET_CURRENT_USER_CART', response.data.cart, {
      root: true,
    })

    return response
  },
  resetVersions({ commit }) {
    commit('RESET_ITEM_VERSIONS')
  },
}

export const getters = {
  itemUnit: () => (unit) => {
    let value = unit
    switch (unit) {
      case 'g':
      case 'mg':
        value = 'kg'
        break
      case 'cl':
      case 'ml':
        value = 'l'
        break
      default:
        value
        break
    }
    return value
  },
}
