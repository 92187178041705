import settingsApi from '@/services/api/settings'

export const namespaced = true

export const state = {
  list: [],
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  infiniteId: +new Date(),
}

export const mutations = {
  SET_BASE_UNIT_TYPES(state, { base_unit_types, total_count }) {
    state.list = [...state.list, ...base_unit_types]
    state.current_page += 1
    state.total_count = total_count
  },
  CLEAR_BASE_UNIT_TYPES(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
}

export const actions = {
  async getBaseUnitTypes({ commit, state }, infiniteLoaderContext) {
    try {
      const response = await settingsApi.base_unit_types.index({
        page: state.current_page + 1,
        q: state.q,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('SET_BASE_UNIT_TYPES', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async clearBaseUnits({ commit }) {
    commit('CLEAR_BASE_UNIT_TYPES')
  },
}
