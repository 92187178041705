import apiClient from '@/services/api'

export default {
  update(id) {
    return apiClient.put(`api/locked_users/${id}`)
  },
  delete(id) {
    return apiClient.delete(`api/locked_users/${id}`)
  },
  companies: {
    update(id, company_id, params) {
      return apiClient.put(
        `api/companies/${company_id}/locked_users/${id}`,
        params
      )
    },
    delete(id, company_id, params) {
      return apiClient.delete(
        `api/companies/${company_id}/locked_users/${id}`,
        { data: params }
      )
    },
  },
}
