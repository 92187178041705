import cartApi from '@/services/api/cart'
import { isEmpty } from 'lodash'

export const namespaced = true

export const state = {
  count: 0,
  current_page: 0,
  global_count: null,
  infiniteId: +new Date(),
  isManageCartTemplateModalOpened: false,
  q: null,
  filters: {},
  list: [],
  pages: 0,
  per_page: 0,
  selected_cart_template: null,
  missing_template_items: null,
  isTemplateDuplicateSuccessfully: false,
  sort: {
    value: { name: 'name_asc', attr: 'name' },
    options: [
      { name: 'name_asc', attr: 'name' },
      { name: 'name_desc', attr: '-name' },
    ],
  },
  total_count: 0,
  versions: {
    count: 0,
    total_count: 0,
    current_page: 0,
    pages: 0,
    per_page: 0,
    infiniteId: +new Date(),
    list: [],
  },
  isBlankState: null,
}

export const mutations = {
  ADD_CART_TEMPLATE(state, template) {
    state.list.unshift(template)
    state.total_count++
  },
  DUPLICATE_CART_TEMPLATE(state, template) {
    state.missing_template_items = template.missing_items
    if (template.missing_items.length === 0) {
      state.list.unshift(template)
      state.total_count++
      state.isTemplateDuplicateSuccessfully = true
    }
  },
  ADD_CART_TEMPLATE_VERSIONS_DATA(state, { versions, ...paginationMeta }) {
    state.versions.list = [...state.versions.list, ...versions]
    state.versions.current_page += 1
    state.versions.total_count = paginationMeta.total_count
  },
  ADD_CART_TEMPLATES(
    state,
    { data: { templates, total_count, global_count }, noFilters }
  ) {
    state.list = [...state.list, ...templates]
    state.total_count = total_count
    state.global_count = global_count
    state.current_page += 1
    state.isBlankState = noFilters && total_count === 0
  },
  CLEAR_CART_TEMPLATES(state) {
    state.current_page = 0
    state.filters = {}
    state.list = []
  },
  DELETE_CART_TEMPLATE_ITEM(state, { id }) {
    state.list.forEach((cartTemplate) => {
      cartTemplate.items = cartTemplate.items.filter((item) => {
        return item.id !== id
      })
    })
  },
  RESET_DUPLICATE_TEMPLATE_DATA(state) {
    state.missing_template_items = null
    state.isTemplateDuplicateSuccessfully = false
  },
  RESET_CART_TEMPLATE_VERSIONS(state) {
    state.versions.list = []
    state.versions.current_page = 0
    state.versions.total_count = 0
  },
  RESET_FILTERS(state) {
    state.current_page = 0
    state.filters = {}
    state.list = []
    state.infiniteId += 1
  },
  RESET_SORT(state) {
    state.current_page = 0
    state.sort.value = null
    state.list = []
    state.infiniteId += 1
  },
  DELETE_CART_TEMPLATE(state, { data: { id }, noFilters }) {
    state.list = state.list.filter((template) => template.id !== id)
    state.total_count -= 1
    state.isBlankState = noFilters && state.total_count === 0
  },
  REPLACE_CART_TEMPLATE(state, newCartTemplate) {
    state.list = state.list.map((cartTemplate) => {
      return cartTemplate.id === newCartTemplate.id
        ? newCartTemplate
        : cartTemplate
    })
  },
  SELECT_CART_TEMPLATE(state, template) {
    state.selected_cart_template = template
  },
  SET_SORT(state, value) {
    state.sort.value = value
  },
  SORT_ITEMS(state, { templateId, field, direction }) {
    const templateIndex = state.list.findIndex(
      (template) => template.id === templateId
    )

    state.list[templateIndex].items.sort((first, second) => {
      const firstStr = first[field].toString().toLowerCase()
      const secondStr = second[field].toString().toLowerCase()

      if (direction === 'asc') {
        return firstStr < secondStr ? -1 : firstStr > secondStr ? 1 : 0
      }

      return firstStr < secondStr ? 1 : firstStr > secondStr ? -1 : 0
    })
  },
  SWITCH_MANAGE_CART_TEMPLATE_MODAL(state, value) {
    state.isManageCartTemplateModalOpened = value
  },
}

export const actions = {
  clearCartTemplates({ commit }) {
    commit('CLEAR_CART_TEMPLATES')
  },
  async createCartTemplate({ commit }, params) {
    const response = await cartApi.templates.create(params)

    commit('ADD_CART_TEMPLATE', response.data)
  },
  async deleteCartTemplateItem({ commit }, id) {
    const response = await cartApi.templates.order_template_spree_prices.delete(
      id
    )

    commit('DELETE_CART_TEMPLATE_ITEM', {
      data: response.data,
      noFilters: isEmpty(state.q),
    })
  },
  async fetchCartTemplates({ state, commit }, infiniteLoaderContext) {
    const { current_page, sort, q } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await cartApi.templates.index({
        ...state.filters,
        q,
        sort: sort.value && sort.value.attr,
        page: current_page + 1,
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_CART_TEMPLATES', {
        data: response.data,
        noFilters: isEmpty(state.q),
      })
    } catch (_error) {
      infiniteLoaderContext?.error()
    }
  },
  async deleteCartTemplate({ commit }, id) {
    const response = await cartApi.templates.delete(id)

    commit('DELETE_CART_TEMPLATE', response)
  },
  async duplicateCartTemplate({ commit }, params) {
    const response = await cartApi.templates.duplications.create(params)

    commit('DUPLICATE_CART_TEMPLATE', response.data)
  },
  resetDuplicateTemplateData({ commit }) {
    commit('RESET_DUPLICATE_TEMPLATE_DATA')
  },
  async loadCartTemplateVersions({ commit }, params) {
    const { current_page } = state.versions
    const { paginationContext, id } = params

    try {
      const response = await cartApi.templates.versions.index(id, {
        page: current_page + 1,
      })

      if (response.data.pages <= response.data.current_page) {
        paginationContext.complete()
      } else {
        paginationContext.loaded()
      }

      commit('ADD_CART_TEMPLATE_VERSIONS_DATA', response.data)
    } catch (_error) {
      paginationContext.error()
    }
  },
  resetFilters({ commit }) {
    commit('RESET_FILTERS')
  },
  resetSort({ commit }) {
    commit('RESET_SORT')
  },
  selectCartTemplate({ commit }, template) {
    commit('SELECT_CART_TEMPLATE', template)
  },
  setSort({ commit }, value) {
    commit('SET_SORT', value)
  },
  sortItems({ commit }, params) {
    commit('SORT_ITEMS', params)
  },
  switchManageCartTemplateModal({ commit }, value) {
    commit('SWITCH_MANAGE_CART_TEMPLATE_MODAL', value)
  },
  async updateCartTemplate({ commit }, { id, ...params }) {
    const response = await cartApi.templates.update(id, params)
    commit('REPLACE_CART_TEMPLATE', response.data)

    return response.data
  },
  async applyCartTemplates(_ctx, params) {
    await cartApi.templates.master_orders.create(params)
  },
  resetVersions({ commit }) {
    commit('RESET_CART_TEMPLATE_VERSIONS')
  },
}
