var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed z-60 inset-0 overflow-y-auto h-full"},[_c('div',{staticClass:"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_c('div',{staticClass:"fixed inset-0 transition-opacity",attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c('div',{staticClass:"absolute inset-0 bg-cod-gray opacity-75"})]),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"fixed w-full bottom-0 inline-block text-left shadow-xl transform transition-all md:align-middle md:static",class:{
        'overflow-hidden': _vm.overflowed,
        [`md:w-${_vm.modalMdWidth}`]: !_vm.modalWidth,
        [_vm.modalBorderRadius]: _vm.modalBorderRadius && !_vm.modalTopBorderRadius,
        [`w-${_vm.modalWidth}`]: _vm.modalWidth,
        'rounded-t-lg': _vm.modalTopBorderRadius,
        'bg-s1-main-100': _vm.isNewBackground,
        'bg-default-bg': !_vm.isNewBackground,
      },attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }