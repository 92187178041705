<template>
  <div class="relative flex flex-col flex-grow desktop-page-view">
    <div
      v-if="isSideNavExpanded"
      class="absolute z-40 opacity-50 bg-alabaster w-screen h-screen"
      :class="{ hidden: isDesktopView }"
      @click="handleCollapse"
    ></div>
    <SideNavigation
      :class="{ hidden: isMobileView && !isMenuOpened, block: !isMobileView }"
      @close-menu="handleOpenMenu"
    />
    <Navigation
      @open-menu="handleOpenMenu"
      :disable-menu-items="isSideNavExpanded"
      :class="{ 'hidden md:flex': !withMobileMenu }"
    />

    <div
      class="flex flex-row flex-grow mt-0 sm:mb-0 sm:mt-16"
      :class="{ 'mb-14': withMobileMenu }"
    >
      <div class="flex flex-col flex-1 flex-grow">
        <div>
          <slot name="page-header" />
        </div>

        <!-- Dark/Light Exception -->
        <div
          class="flex flex-col flex-grow pb-0 sm:pb-0 xl:pb-0"
          :class="canary ? 'bg-default-bg sm:p-0' : 'bg-gallery sm:p-5'"
        >
          <slot />
          <ItemsUpdatedStatisticModal
            v-if="currentUser.currentUser.notifications.items_updated_statistic"
          />
        </div>
      </div>

      <!-- Dark/Light Exception -->
      <div
        class="absolute bottom-0 right-0 z-40 w-full p-0 m-0 bg-default-bg sm:top-0 sm:right-0 sm:w-auto sm:flex xl:static xl:z-30"
        :class="{ 'top-0': isSidebarShown }"
      >
        <slot name="sidebar-right" :events="{ setRightSidebarShown }" />
      </div>
    </div>
  </div>
</template>

<style>
.desktop-page-view {
  --desktop-min-width: calc(100% - 284px);
  --desktop-max-width: calc(100% - 88px);
}
</style>

<script>
import detectScreenSize from '@/mixins/detect-screen-size'
import Navigation from '@/components/shared/organisms/the-navigation'
import { mapActions, mapGetters, mapState } from 'vuex'
import ItemsUpdatedStatisticModal from '@/components/items/items-updated-statistic-modal'
import SideNavigation from '@/components/shared/organisms/side-navigation'

export default {
  components: {
    Navigation,
    ItemsUpdatedStatisticModal,
    SideNavigation,
  },
  mixins: [detectScreenSize],
  props: {
    canary: {
      type: Boolean,
      default: false,
    },
    withMobileMenu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSidebarShown: false,
      isMenuOpened: false,
    }
  },
  mounted() {
    this.setThemeBackground()
  },
  methods: {
    ...mapActions('currentUser', ['updateNavbarSettings']),
    setRightSidebarShown(state) {
      this.isSidebarShown = state
    },
    handleOpenMenu() {
      this.isMenuOpened = !this.isMenuOpened
    },
    handleCollapse() {
      this.updateNavbarSettings({
        isCollapsed: !this.currentUser.settings.navbar.isCollapsed,
      })
    },
    setThemeBackground() {
      document.body.style.backgroundColor = `${
        this.isDarkMode ? '#111315' : '#f4f4f4'
      }`
    },
  },
  computed: {
    ...mapGetters('currentUser', ['isDarkMode']),
    ...mapState(['currentUser']),
    isSideNavExpanded() {
      return !this.currentUser.settings.navbar.isCollapsed && this.isTabletView
    },
  },
  watch: {
    isDarkMode() {
      this.setThemeBackground()
    },
  },
}
</script>
