<template>
  <button
    @click.stop.prevent="click"
    data-cy="close-button"
    :class="{
      'bg-alabaster h-10 rounded-md w-10 pt-0.5 pl-0.5': isButtonKind,
    }"
  >
    <ActionIcon
      data-cy="close-icon"
      name="actions_close"
      class="w-4 h-4"
      :class="{
        'text-mine-shaft': isButtonKind,
        'text-medium-gray': isIconKind,
      }"
    />
  </button>
</template>

<script>
import ActionIcon from '@/components/shared/atoms/action-icon'

export default {
  name: 'ButtonClose',
  components: {
    ActionIcon,
  },
  computed: {
    isIconKind() {
      return this.kind === 'icon'
    },
    isButtonKind() {
      return this.kind === 'button'
    },
  },
  methods: {
    click() {
      this.$emit('click')
    },
  },
  props: {
    kind: {
      type: String,
      default: 'icon',
      validator(value) {
        return ['icon', 'button'].includes(value)
      },
    },
  },
}
</script>
