import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/suppliers', { params })
  },
  update(id, params) {
    return apiClient.put(`api/suppliers/${id}`, params)
  },
}
