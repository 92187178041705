import apiClient from '@/services/api'

export default {
  show(token) {
    return apiClient.get(`api/invitations/${token}`)
  },
  create(params) {
    return apiClient.post('api/invitations', params)
  },
  update(token, params) {
    return apiClient.put(`api/invitations/${token}`, params)
  },
}
