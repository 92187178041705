var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",class:{
    'w-26 h-26': !_vm.overlayed && _vm.size === 'lg',
    'w-10 h-10': !_vm.overlayed && _vm.size === 'md',
    'w-8 h-8': !_vm.overlayed && _vm.size === 'sm',
    'w-7 h-7': !_vm.overlayed && _vm.size === 'xxs',
  }},[(_vm.data && _vm.data.logo.url)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: `${_vm.data.name}`,
      placement: _vm.tooltipPlacement,
    }),expression:"{\n      content: `${data.name}`,\n      placement: tooltipPlacement,\n    }"}],staticClass:"object-cover",class:{
      'w-7 h-7': _vm.size === 'xxs',
      'w-8 h-8': _vm.size === 'sm',
      'w-10 h-10': _vm.size === 'md',
      'w-full h-full': _vm.size === 'full',
      'md:w-8 md:h-8 md:text-sm': _vm.overlayed,
      'rounded-full': _vm.isCircle,
      rounded: !_vm.isCircle,
    },attrs:{"alt":"","src":_vm.attachmentFullUrl(_vm.data.logo.url)}}):_c('InitialsIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: `${_vm.data ? _vm.data.name : ''}`,
      placement: _vm.tooltipPlacement,
    }),expression:"{\n      content: `${data ? data.name : ''}`,\n      placement: tooltipPlacement,\n    }"}],staticClass:"inline-block object-cover text-sm bg-mine-shaft",class:{
      'w-7 h-7 text-sm': _vm.size === 'xxs',
      'w-8 h-8 text-sm': _vm.size === 'sm',
      'w-10 h-10': _vm.size === 'md',
      'w-full h-full text-xl': _vm.size === 'full',
      'md:w-8 md:h-8 md:text-sm': _vm.overlayed,
    },attrs:{"is-circle":_vm.isCircle}},[_c('span',{staticClass:"text-gallery"},[_vm._v(_vm._s(_vm.data ? _vm.data.name[0] : ''))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }