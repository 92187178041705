import openPaymentsApi from '@/services/api/open-payments'
import { isEmpty } from 'lodash'

export const namespaced = true

export const state = {
  list: [],
  infiniteId: +new Date(),
  pages: 0,
  per_page: 0,
  current_page: 0,
  q: null,
  isBlankState: null,
}

export const mutations = {
  ADD_ORDERS(state, { data: { orders, ...paginationMeta }, noFilters }) {
    state.list = [...state.list, ...orders]
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.isBlankState = noFilters && paginationMeta.total_count === 0
  },
  CLEAR_ORDERS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
}

export const actions = {
  async clearOrders({ commit }) {
    commit('CLEAR_ORDERS')
  },
  async getOrders({ state, commit }, infiniteLoaderContext) {
    const { current_page } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await openPaymentsApi.index({
        q: state.q,
        page: current_page + 1,
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_ORDERS', { data: response.data, noFilters: isEmpty(state.q) })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
}
