<template>
  <button
    class="focus:outline-none flex-shrink-0 text-sm text-backdrop font-regular"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="disabled || loading"
    :class="{
      [borderRadius]: borderRadius,
      [`w-${width}`]: width,
      [`h-${height}`]: true,
      [`px-${padding}`]: true,
      'bg-s1-blue-100 hover:bg-s1-blue-darker text-s1-main-100':
        kind === 'primary',
      'bg-s1-main-100 hover:bg-s1-blue-20 border border-s1-blue-100 hover:border-s1-blue-darker text-s1-blue-100 hover:text-s1-blue-darker':
        kind === 'secondary',
      'opacity-50 cursor-auto pointer-events-none': disabled,
    }"
  >
    <span class="font-medium text-sm leading-3"><slot /></span>
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    padding: {
      type: String,
      default: '4',
    },
    height: {
      type: String,
      default: '11',
    },
    kind: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'outline'].includes(value)
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: 'rounded-10',
    },
    width: {
      type: String,
      default: 'full',
    },
  },
}
</script>
