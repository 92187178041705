import apiClient from '@/services/api'

export default {
  create(params) {
    return apiClient.post('api/replacement_groups', params)
  },
  delete(id) {
    return apiClient.delete(`api/replacement_groups/${id}`)
  },
  update(id, data) {
    return apiClient.put(`api/replacement_groups/${id}`, data)
  },
  index(params) {
    return apiClient.get('api/replacement_groups', { params })
  },
  show(id) {
    return apiClient.get(`api/replacement_groups/${id}`)
  },
  verify_units: {
    create(replacementGroupId) {
      return apiClient.post(
        `api/replacement_groups/${replacementGroupId}/verify_units`
      )
    },
  },
}
