<template>
  <header
    class="fixed bottom-0 z-40 sm:z-50 md:z-35 xl:z-50 flex flex-row pl-5 pr-6 py-4 items-center border-b border-default-bg md:justify-between w-full md:h-16 bg-alabaster sm:h-14 sm:top-0 bottom-header-shadow"
  >
    <div class="hidden xl:block h-8">
      <Logo
        class="xl:flex-1 w-31 text-medium-gray cursor-pointer"
        type="dark"
        @click="$router.push('/')"
      />
    </div>

    <div class="flex w-full">
      <nav
        v-if="isNavigationShown"
        class="flex flex-row items-center justify-center flex-grow sm:justify-end"
        data-cy="navigation"
      >
        <div
          v-for="(action, index) in filteredActions"
          :key="index"
          class="flex flex-col items-center justify-center flex-1 sm:flex-initial sm:flex-row cursor-pointer font-medium w-12 h-12 p-3 rounded-md hover:bg-silver"
          @click.prevent="handleRouteChange(action)"
        >
          <a
            v-if="action.visible"
            href=""
            class="relative flex flex-col items-center justify-center flex-1 w-6 h-6 sm:flex-initial sm:flex-row"
            :class="{
              'text-s1-blue-100': isActive(action),
              'opacity-50': action.disable || disableMenuItems,
            }"
            :data-cy="`navigation-action-${action.title}`"
          >
            <div
              v-if="hasCartItems && action.icon === 'actions_shopping_cart'"
              class="absolute -top-0.5 -right-0.5 w-3 h-3 text-center rounded-full flex items-center justify-center border-2 border-alabaster bg-s1-red-100"
              data-cy="navigation-cart-indicator"
            ></div>
            <ActionIcon
              :name="action.icon"
              size="xl"
              :color="
                action.hover || isActive(action) ? 's1-blue-100' : 's1-gray-80'
              "
              v-tooltip="$t(`tooltips.${action.title}`)"
            />
          </a>
        </div>
      </nav>

      <div
        class="flex flex-row items-center justify-center z-60 sm:flex hidden p-2 w-12 h-12 rounded-md hover:bg-silver"
        :class="{ 'opacity-50': disableMenuItems }"
      >
        <UserMenu
          :disabled="!disableMenuItems"
          v-if="!isMobileView"
          size="xxs"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ActionIcon from '@/components/shared/atoms/action-icon'
import detectScreenSize from '@/mixins/detect-screen-size'
import Logo from '@/components/shared/atoms/base-logo'
import UserMenu from '@/components/shared/organisms/user-menu'

export default {
  components: {
    ActionIcon,
    Logo,
    UserMenu,
  },
  mixins: [detectScreenSize],
  props: {
    disableMenuItems: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (!this.currentUser.currentUser.currentCart.isLoaded)
      this.getCart({ is_short: true })
  },
  computed: {
    ...mapState(['currentUser']),
    actions() {
      return [
        {
          icon: 's1_icon_hamburger',
          title: 'menu',
          path: '',
          isRoutable: false,
          disable: false,
          visible: this.isMobileView,
          hover: false,
        },
        {
          icon: 'side-nav_dashboard',
          title: 'dashboard',
          path: '/dashboard',
          isRoutable: true,
          disable: false,
          visible: this.$can('manage', 'dashboard') && this.isMobileView,
          hover: false,
        },
        {
          icon: 'actions_shopping_cart',
          title: 'cart',
          path: '/cart',
          isRoutable: true,
          disable: false,
          visible: this.$can('manage', 'cart'),
          hover: false,
        },
        {
          icon: 'actions_notifications',
          title: 'notifications',
          path: '/notifications',
          isRoutable: true,
          disable: true,
          visible: this.$can('manage', 'cart') && !this.isMobileView,
          hover: false,
        },
      ]
    },
    filteredActions() {
      return this.actions.filter((item) => item.visible)
    },
    isActive() {
      return (item) =>
        item.path === this.$route.path ||
        item.path === this.$route.meta.subPathOf
    },
    isNavigationShown() {
      return !(
        this.$router.currentRoute.name === 'profile' && this.isMobileView
      )
    },
    hasCartItems() {
      const itemsCount =
        this.currentUser.currentUser.currentCart.total_items_count
      return itemsCount && itemsCount > 0
    },
  },
  methods: {
    ...mapActions('users', ['closeAllUserModals']),
    ...mapActions('cart', ['getCart']),
    handleRouteChange(action) {
      if (action.disable || this.disableMenuItems) return

      if (action.path === this.$route.path) {
        return
      }

      if (action.path === '/users' || action.path === '/members') {
        this.closeAllUserModals()
      }

      if (action.isRoutable) {
        this.$router.push(action.path)
      } else {
        this.$emit('open-menu')
      }
    },
  },
}
</script>
