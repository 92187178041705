import importMappingApi from '@/services/api/import-mapping'
import { uniqBy, isEmpty } from 'lodash'

export const namespaced = true

export const state = {
  list: [],
  item: {},
  importMappingColumns: [],
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  infiniteId: +new Date(),
  isBlankState: null,
}

export const mutations = {
  SET_MAPPINGS_DATA(
    state,
    { data: { import_mappings, ...paginationMeta }, noFilters }
  ) {
    state.list = uniqBy([...state.list, ...import_mappings], 'id')
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.isBlankState = noFilters && paginationMeta.total_count === 0
  },
  CLEAR_MAPPINGS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  DELETE_IMPORT_MAPPING(state, data) {
    state.list = state.list.filter((item) => item.id !== data.import_mapping.id)
    state.total_count -= 1
  },
  SET_IMPORT_MAPPING_DATA(state, data) {
    state.item = data.import_mapping
  },
  SET_IMPORT_MAPPING_COLUMNS_DATA(state, data) {
    state.importMappingColumns = data.import_mapping_columns
  },
  UPDATE_IMPORT_MAPPING(state, data) {
    state.item = { ...data }
    const updatedList = [...state.list]

    for (let i = 0; i < updatedList.length; i++) {
      if (updatedList[i].id === data.id) {
        updatedList[i] = { ...data }
        break
      }
    }

    state.list = updatedList
  },
}

export const actions = {
  async deleteMapping({ commit }, id) {
    const response = await importMappingApi.delete(id)
    commit('DELETE_IMPORT_MAPPING', response.data)
  },

  clearMappings({ commit }) {
    commit('CLEAR_MAPPINGS')
  },
  async loadMappings({ commit, state }, infiniteLoaderContext) {
    const { current_page } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await importMappingApi.index({
        page: current_page + 1,
        q: state.q,
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('SET_MAPPINGS_DATA', {
        data: response.data,
        noFilters: isEmpty(state.q),
      })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async loadImportMapping({ commit }, id) {
    const response = await importMappingApi.show(id)
    commit('SET_IMPORT_MAPPING_DATA', response.data)
  },
  async loadImportMappingColumns({ commit }, importMappingId) {
    const response = await importMappingApi.importMappingColumns.index(
      importMappingId
    )
    commit('SET_IMPORT_MAPPING_COLUMNS_DATA', response.data)
  },
  async updateMapping({ commit }, { id, data }) {
    const response = await importMappingApi.update(id, data)
    commit('UPDATE_IMPORT_MAPPING', response.data.import_mapping)
  },
}

export const getters = {
  isLastMapping: (state) => (supplier_tenant_id) => {
    const mappings = state.list.filter(
      (item) => item.supplier_tenant_id === supplier_tenant_id
    )

    return mappings.length === 1
  },
}
