import settingsApi from '@/services/api/settings'

const defaultGroupedValue = { name: 'all', type: 'unit' }

export const namespaced = true

export const state = {
  list: [],
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  selectedUnit: null,
  q: null,
  filters: {
    unit_types: {
      value: defaultGroupedValue,
      options: [
        { name: 'all', type: 'unit' },
        { name: 'base_unit', type: 'unit' },
        { name: 'packaging_unit', type: 'unit' },
      ],
      type: 'radio',
      selected: false,
    },
  },
  infiniteId: +new Date(),
}

export const mutations = {
  APPLY_FILTER(state, { item }) {
    state.filters.unit_types.value = item
  },
  SET_UNITS(state, { units, total_count }) {
    state.list = [...state.list, ...units]
    state.current_page += 1
    state.total_count = total_count
  },
  CLEAR_UNITS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  CLEAR_FILTER(state) {
    state.filters.unit_types.value = defaultGroupedValue
  },
  CLEAR_ALL_FILTERS(state) {
    const { filters } = state
    filters.unit_types.value = defaultGroupedValue
  },
  DELETE_UNIT(state, { unit }) {
    state.selectedUnit.supplier_mapping =
      state.selectedUnit.supplier_mapping.filter((item) => {
        return item.id !== unit.id
      })
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
}

export const actions = {
  applyFilter({ commit }, { type, item }) {
    commit('APPLY_FILTER', { type, item })
    commit('CLEAR_UNITS')
  },
  async getUnits({ commit, state }, infiniteLoaderContext) {
    try {
      const response = await settingsApi.units.index({
        page: state.current_page + 1,
        filter_by: state.filters.unit_types.value.name,
        q: state.q,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('SET_UNITS', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async clearUnits({ commit }) {
    commit('CLEAR_UNITS')
  },
  clearFilter({ commit }) {
    commit('CLEAR_FILTER')
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS')
  },
  async deleteSupplierUnitMapping({ commit }, { unit_id, id, ...params }) {
    const response = await settingsApi.units.supplier_unit_mapping.delete(
      unit_id,
      id,
      params
    )
    commit('DELETE_UNIT', response.data)
  },
}

export const getters = {
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
}
